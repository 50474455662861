import React from 'react';
import {debounce } from './tools';

class Canvas extends React.Component {
    constructor(props) {
        super(props);
        this.canvas_parameter={
                mode:'',
                number_order:false,

                canvas_id:'',
                canvasObject:'',
                canvasContext:'',

                canvasWidth:0,
                canvasHeight:0,
                lastLineWidth:-1,
                linColor:'#000000',
                movePos:'',

                cur_fonts:'',
                lines:[],
                strokes_flag:[],
                strokes_count:0,
                lastTimeStamp:'',

                isDraw:false,

                paint_lastPos:'',
                paint_lastLineWidth:1,
                paint_movePos:'',

                offset_x :0,
                offset_y :0,
                pos_x:250,
                gcodes: [],
                ratio:1,
                fontsize:320,
            };
        this.state = {
             bg:'none',
        };
    };

    //设定特定模式 在组件调用时使用
    mode_set(mode){
        var c_p = this.canvas_parameter;
        var c = c_p.canvasObject = document.getElementById(c_p.canvas_id);
        var c_cxt = c_p.canvasContext = c.getContext("2d");

        //根据传来的props进行设置
        this.canvas_parameter.offset_x = this.props.settings_parameter.offset_x;
        this.canvas_parameter.pos_x = - this.props.settings_parameter.offset_x;

        //书写所需要的的功能
        if(mode == 'write'){

            const getPos = (x,y) => {
                const box = c.getBoundingClientRect();
                return {x: x-box.left,y: y-box.top};
            };

            const calclientWidth = this.calclientWidth;

            const calDistance = this.calDistance;

            const drawing = (e) => {
                if(c_p.isDraw){
                    const position = getPos(e.clientX,e.clientY);

                    if( 0 <= position.x <= c_p.canvasWidth & 0<= position.y <= c_p.canvasHeight ){

                        if(c_p.lastTimeStamp){
                            const delt_time =  (new Date()).valueOf() - c_p.lastTimeStamp;
                            c_p.lastTimeStamp = (new Date()).valueOf();

                            c_p.lines.push({x:position.x,y:position.y,drop:1,time:delt_time});

                            const s = calDistance(c_p.lines.slice(-2,-1)[0],c_p.lines.slice(-1)[0]);
                            c_p.lastLineWidth = calclientWidth(delt_time,s);

                            c_cxt.strokeStyle = c_p.linColor;
                            c_cxt.lineCap="round";
                            c_cxt.lineJoin="round";
                            c_cxt.lineWidth = c_p.lastLineWidth;
                            c_cxt.save();
                            c_cxt.beginPath();
                            c_cxt.moveTo(c_p.movePos.x,c_p.movePos.y);
                            c_cxt.lineTo(position.x,position.y);
                            c_cxt.stroke();
                            c_p.movePos = position;
                            c_cxt.restore();
                        }else{
                            c_p.lastTimeStamp = (new Date()).valueOf();
                        };
                    };
                };
            };

            c.onmousedown = (e)=>{
                e.preventDefault();
                c_p.isDraw = true;
                c_p.movePos = getPos(e.clientX,e.clientY);
                c_p.lines.push({x:c_p.movePos.x,y:c_p.movePos.y,drop:0,time:0});
                drawing(e);
            };

            c.onmousemove = (e)=>{
                e.preventDefault();
                if(c_p.isDraw){
                    drawing(e);
                };
            };

            c.onmouseup = (e)=>{
                e.preventDefault();
                if(c_p.isDraw){
                    c_p.isDraw = false;
                    c_p.lastTimeStamp = '';
                    c_p.lastLineWidth = -1;
                    // c_p.lines.push({x:c_p.movePos.x,y:c_p.movePos.y,drop:0,time:0});
                    c_p.strokes_flag.push(c_p.lines.length);
                };
            };

            c.onmouseout = (e)=>{
                e.preventDefault();
                if(c_p.isDraw){
                    c_p.isDraw = false;
                    c_p.lastTimeStamp = '';
                    c_p.lastLineWidth = -1;
                    // c_p.lines.push({x:c_p.movePos.x,y:c_p.movePos.y,drop:0,time:0});
                    c_p.strokes_flag.push(c_p.lines.length);
                };
            };

            c.addEventListener('touchstart',
                (e)=>{
                    e.preventDefault();
                    c_p.isDraw = true;
                    const touch= e.touches[0];
                    c_p.movePos = getPos(touch.pageX,touch.pageY);
                    c_p.lines.push({x:c_p.movePos.x,y:c_p.movePos.y,drop:0,time:0});
                    drawing({clientX:touch.pageX,clientY:touch.pageY});
                });

            c.addEventListener('touchmove',
                (e)=>{
                    e.preventDefault();
                    if(c_p.isDraw){
                        const touch= e.touches[0];
                        drawing({clientX:touch.pageX,clientY:touch.pageY});
                    };
                });

            c.addEventListener('touchend',
                (e)=>{
                    e.preventDefault();
                    if(c_p.isDraw){
                        c_p.isDraw = false;
                        c_p.lastTimeStamp = '';
                        c_p.lastLineWidth = -1;
                        // c_p.lines.push({x:c_p.movePos.x,y:c_p.movePos.y,drop:0,time:0});
                        c_p.strokes_flag.push(c_p.lines.length);
                    };
                });

            this.setState({'mode':mode});
        };
        //仅展示用
        if(mode == 'sub'){
            this.canvas_parameter.number_order = this.props.settings_parameter.number_order;
            this.canvas_parameter.pos_x += 20;
            this.setState({'mode':mode});
        };
    };

    componentWillMount(){
        const tcp = this.canvas_parameter;
        const tpsp = this.props.settings_parameter;

        //根据传来的props进行设置
        tcp.canvas_id = tpsp.dom_id;
        tcp.canvasWidth = tpsp.dom_width;
        tcp.canvasHeight = tpsp.dom_height;
        tcp.fontsize = tpsp.fontsize;
        tcp.ratio = tpsp.fontsize/320 + (320 - tpsp.fontsize)/320* 0.2 ;
        tcp.ratio = tcp.ratio > 1 ? 1 : tcp.ratio;
        tcp.cur_fonts = tpsp.cur_fonts;
        tcp.gcodes = tpsp.gcodes;

        //每个管理canvas的node相对独立 需要一个共有变量统一管理 >>①
        if(window.id_list){
            window.id_list.push({can:this,id:tpsp.dom_id});
        }else{
            window.id_list = [{can:this,id:tpsp.dom_id}];
        };

        //根据窗口位置 动态调整canvas大小 并重新绘制 <<①

        if(tpsp.resize) {
            var old = window.onresize ? window.onresize : ()=>{};
            this.refresh = debounce(()=>{
                old();
                if(  window.innerWidth <= 1400 | ( window.innerWidth > 1400 && this.canvas_parameter.canvasWidth <= 920 ) ) {window.id_list.map(item => {

                const can_doc = document.getElementById(item.id);
                if(!item.can.canvas_parameter){window.location.reload();return};
                //以浏览器后退的方式 不会加载 props 需要强制刷新
                can_doc.width = parseInt(  (window.innerWidth <= 1000 ? (window.innerWidth > 340 ? window.innerWidth : 340 ) :1000) * 0.95 - 30);
                can_doc.height = parseInt(((window.innerWidth <= 1000 ? (window.innerWidth > 340 ? window.innerWidth : 340 ) :1000) * 0.95 - 30) / 5);
                console.log(can_doc.height)
                item.can.canvas_parameter.canvasWidth = can_doc.width;
                item.can.canvas_parameter.canvasHeight = can_doc.height;
                item.can.canvas_parameter.fontsize = can_doc.height;
                item.can.canvas_parameter.ratio = can_doc.height / 320 > 1 ? 1 : can_doc.height / 320;
                item.can.canvas_parameter.offset_x = can_doc.height;
                item.can.canvas_parameter.offset_y = 0;
                item.can.canvas_parameter.pos_x = -can_doc.height;
                item.can.canvas_parameter.lastLineWidth = -1;
                item.can.canvas_parameter.isDraw = false;
                item.can.showfonts();
            })}});//节流，节省资源
            window.addEventListener('resize',this.refresh);
        };

    };

    componentDidMount(){
        this.props.onRef(this);
        this.showfonts();
    };

    componentWillUnmount(){
        delete this.canvas_parameter;
        window.removeEventListener('resize',this.refresh);
    };

    bgChange=(url)=>{
        this.setState({bg:url});
    };

    //书写状态相关的参数初始化
    init(){
        const c_p = this.canvas_parameter;
        c_p.lines = [];
        c_p.movePos = '';
        c_p.lastLineWidth = -1*c_p.ratio;
        c_p.strokes_flag = [];
        c_p.strokes_count = 0;
        c_p.lastTimeStamp = '';
        c_p.isDraw = false;
    }

    calDistance=(loc1,loc2)=>{
	        return Math.sqrt((loc1.x-loc2.x)*(loc1.x-loc2.x)+(loc1.y-loc2.y)*(loc1.y-loc2.y));
        };

    calclientWidth=(t,s)=>{
            var resultLineWidth;
            const v=s/t;
            const speedLow = 0.04;
            const speedFast = 2;
            let final_res = 0;

            if(v<=speedLow) resultLineWidth=10;
            else if(v>=speedFast) resultLineWidth=6;
            else resultLineWidth=(16-(v-speedLow)/(speedFast-speedLow)*(16-6));
            if(this.canvas_parameter.lastLineWidth === -1 * this.canvas_parameter.ratio){
                final_res = resultLineWidth * this.canvas_parameter.ratio;
            }else {
                final_res = ( resultLineWidth * 1/3 + this.canvas_parameter.lastLineWidth * 2/3 ) * this.canvas_parameter.ratio;};
            return final_res;
        };

    //绘制的具体实现 点到点
    draw=(line)=>{

        const c_p = this.canvas_parameter;
        const c_cxt = c_p.canvasContext;

        //落笔的drop为0 视作起点
        if(line.drop == 0){
                c_p.paint_lastPos = line;
                c_p.lastLineWidth = -1;
        }else{
                //连接
                const s = this.calDistance(c_p.paint_lastPos,line);
                c_p.lastLineWidth = this.calclientWidth(line.time,s);
                c_cxt.strokeStyle = c_p.linColor;
                c_cxt.lineCap="round";
                c_cxt.lineJoin="round";
                c_cxt.lineWidth = c_p.lastLineWidth;
                c_cxt.save();
                c_cxt.beginPath();
                c_cxt.moveTo(c_p.paint_lastPos.x,c_p.paint_lastPos.y);
                c_cxt.lineTo(line.x,line.y);
                c_cxt.stroke();
                c_p.paint_lastPos = line;

        };
    };

    paint=(clear_flag = true)=>{
        const c_p = this.canvas_parameter;
        if(clear_flag) {
            c_p.canvasContext.clearRect(0, 0, c_p.canvasWidth, c_p.canvasHeight);
            this.drawGrid(0,0,this.canvas_parameter.canvasWidth,this.canvas_parameter.canvasHeight);
        };
        for(var i = 0;i<c_p.lines.length;i++){
            this.draw(c_p.lines[i]);
        };
        c_p.lastLineWidth =-1 * c_p.ratio;
    };

    drawDashedLine=(x1,y1,x2,y2,dashLength)=>{
        dashLength = dashLength===undefined ? 5 : dashLength;
        var deltaX = x2 - x1;
        var deltaY = y2 - y1;
        var numDashed = Math.floor(Math.sqrt(deltaX*deltaX+deltaY*deltaY)/dashLength);

        var c_p = this.canvas_parameter;
        var c_cxt = c_p.canvasContext;

        c_cxt.save();
        c_cxt.beginPath();
        c_cxt.strokeStyle = '#ff4236';
        for(var i=0; i<numDashed;i++){
            c_cxt[i%2==0 ? "moveTo":"lineTo"](x1+(deltaX/numDashed)*i,y1+(deltaY/numDashed)*i);
        };
        c_cxt.lineWidth = 1;
        c_cxt.closePath();
        c_cxt.stroke();
        c_cxt.restore();
    }

    drawGrid=(x1,y1,x2,y2)=>{
        const c_p = this.canvas_parameter;
        const cw = c_p.canvasWidth;
        const ch = c_p.canvasHeight;
        const c_cxt = c_p.canvasContext;

        x1 += 2;
        x2 += 4;
        y1 -= 2;
        y2 -= 2;

        this.drawDashedLine(x1,y1,x2,y2,4);
        this.drawDashedLine(x1,y2,x2,y1,4);
        this.drawDashedLine(x1,y2/2,x2,y2/2,4);
        this.drawDashedLine(x1+(x2-x1)/2,y1,x1+(x2-x1)/2,y2,4);

        this.drawDashedLine(x1+(x2-x1)/4,y2/4,x1+(x2-x1)*3/4,y2/4,4);
        this.drawDashedLine(x1+(x2-x1)/4,y2*3/4,x1+(x2-x1)*3/4,y2*3/4,4);
        this.drawDashedLine(x1+(x2-x1)/4,y2/4,x1+(x2-x1)/4,y2*3/4,4);
        this.drawDashedLine(x1+(x2-x1)*3/4,y2/4,x1+(x2-x1)*3/4,y2*3/4,4);

        c_cxt.save();
        c_cxt.beginPath();
        c_cxt.strokeStyle="#ed0a2b";
        c_cxt.moveTo(x1+1,y1+2);
        c_cxt.lineTo(cw-2,y1+2);
        c_cxt.lineTo(cw-2,ch-2);
        c_cxt.lineTo(x1+1,ch-2);
        c_cxt.lineWidth= ch < 200 ? 2 :4;
        c_cxt.closePath();
        c_cxt.stroke();
        c_cxt.restore();
    }

    linesFormTrans(lines,fontsize){
    //坐标为实际坐标 需要根据比例转化
        return lines.map(item=>{
            return (item.x*fontsize/this.canvas_parameter.fontsize).toFixed(4) + ',' + (item.y*fontsize/this.canvas_parameter.fontsize).toFixed(4) + ',' + item.drop + ',' + item.time + ';'
        }).join('');
    };

    replay=()=>{
        const c_p = this.canvas_parameter;
        const c_cxt = this.canvas_parameter.canvasContext;
        c_p.canvasContext.clearRect(0,0,c_p.canvasWidth,c_p.canvasHeight);
        this.drawGrid(0,0,this.canvas_parameter.canvasWidth,this.canvas_parameter.canvasHeight);

        const fl = [];
        const sl = [];
        clearTimeout();
        var delay = 200;

        c_p.lines.map((line,index)=>{

            fl[index] = (() => {
                0 < line.time < 20 ? delay += 20 : delay += line.time;
                sl[index] = setTimeout(line => {
                    if (line.drop == 0) {
                        c_p.paint_lastPos = line;
                        c_p.lastLineWidth = -1;
                    } else {
                        const s = this.calDistance(c_p.paint_lastPos, line);
                        c_p.lastLineWidth = this.calclientWidth(line.time, s);
                        c_cxt.strokeStyle = c_p.linColor;
                        c_cxt.lineCap = "round";
                        c_cxt.lineJoin = "round";
                        c_cxt.lineWidth = c_p.lastLineWidth;
                        c_cxt.save();
                        c_cxt.beginPath();
                        c_cxt.moveTo(c_p.paint_lastPos.x, c_p.paint_lastPos.y);
                        c_cxt.lineTo(line.x, line.y);
                        c_cxt.stroke();
                        c_p.paint_lastPos = line;
                    };
                }, delay, line);
            })();
        });
    };

    //撤销
    undo=()=>{
       clearTimeout();
       const c_p = this.canvas_parameter;
       c_p.strokes_flag = c_p.strokes_flag.slice(0,-1);
       c_p.strokes_count = c_p.strokes_flag.slice(-1);
       c_p.lines = c_p.lines.slice(0,c_p.strokes_count);
       this.paint();
    };

    refresh(){};

    clear(){
        clearTimeout();
        this.init();
        this.canvas_parameter.canvasContext.clearRect(0,0,this.canvas_parameter.canvasWidth,this.canvas_parameter.canvasHeight);
        this.drawGrid(0,0,this.canvas_parameter.canvasWidth,this.canvas_parameter.canvasHeight);
    };

    save(fs){
        return this.linesFormTrans(this.canvas_parameter.lines,fs);
    };

    showfonts=()=>{
        if(this.canvas_parameter.gcodes){

            this.canvas_parameter.offset_y = 0;

            this.canvas_parameter.gcodes.map((item,index)=>{

                var vs = item.gcodes.split(';');
                this.canvas_parameter.pos_x += this.canvas_parameter.offset_x;

                if(this.canvas_parameter.pos_x + this.canvas_parameter.fontsize*this.canvas_parameter.font_len > this.canvas_parameter.canvasWidth){
                    this.canvas_parameter.pos_x = 20;
                    this.canvas_parameter.offset_y += this.canvas_parameter.fontsize;
                };

                if(this.canvas_parameter.number_order){
                    let mark_size = 14;
                    const c_cxt = this.canvas_parameter.canvasContext;
                    c_cxt.font = "18px bold 黑体";
                    c_cxt.fillStyle = "#222222";
                    c_cxt.textAlign = "left";
                    c_cxt.textBaseline = "middle";

                    c_cxt.font = mark_size+"px bold 黑体";
                    c_cxt.fillText(index+1+':' , this.canvas_parameter.pos_x-10 ,this.canvas_parameter.offset_y+20);
                }else{
                    this.drawGrid(this.canvas_parameter.pos_x,this.canvas_parameter.offset_y,this.canvas_parameter.pos_x+this.canvas_parameter.fontsize,this.canvas_parameter.offset_y+this.canvas_parameter.fontsize);
                };

                return vs.map((item)=>{
                    var i = item.split(',');
                    return {x:parseInt(i[0]) * this.canvas_parameter.fontsize / 100 + this.canvas_parameter.pos_x ,y:parseInt(i[1]) * this.canvas_parameter.fontsize / 100 + this.canvas_parameter.offset_y ,drop:parseInt(i[2]),time:parseInt(i[3])}
                });
            }).map((item)=>{
                this.canvas_parameter.lines = item;
                this.paint(false);
                return;
            });

        }else{
            this.drawGrid(0,0,this.canvas_parameter.canvasWidth,this.canvas_parameter.canvasHeight);
            };
        };

    render() {
        return (
                <canvas id={this.canvas_parameter.canvas_id}
                        width={this.canvas_parameter.canvasWidth + 'px'}
                        height={this.canvas_parameter.canvasHeight + 'px'}
                        style={{background:this.state.bg + '/ cover',}}>
                </canvas>
    );

    };
}

export default Canvas;
