// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../static/img/writer_bg.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "#root {\n  overflow-x: hidden;\n}\n#FontList > .ant-space-vertical div:nth-child(2) {\n  width: 80vw;\n  margin: 0 auto;\n}\n#FontList button {\n  margin: 10px;\n}\n#ad_i #u_l > div > div.ant-card-body > div > div.ant-card-body {\n  padding: 2px;\n}\n#ad_i #u_l > div > div.ant-card-body > div > div.ant-card-body > div {\n  display: inline-block;\n  margin: 5px;\n}\n#FontWriter {\n  padding-top: 10px;\n}\n#FontWriter .ant-space-vertical:nth-child(1) {\n  position: relative;\n}\n#FontWriter .ant-space-vertical {\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat;\n  background-size: 100% 100%;\n  padding: 2% 5% 4% 5%;\n  display: block;\n  margin: 0 auto;\n}\n#FontWriter .ant-space-vertical > div:nth-child(2) {\n  border-radius: 5;\n  border: 1px solid #cac6c6ad;\n}\n#FontWriter .ant-space-vertical > div:nth-child(2) > div > span {\n  margin: 0 0 5px 5px;\n}\n#FontWriter .ant-btn,\n#FontWriter .ant-select-selection-item,\n#FontWriter .ant-select-selector {\n  padding: 0px 12px;\n  height: 25px;\n  line-height: 23px;\n}\n#FontWriter .buttons {\n  display: flex;\n  flex-wrap: wrap;\n  justify-content: center;\n  margin: 0 auto;\n}\n#FontWriter .buttons button {\n  font-size: 10px;\n  margin: 2px;\n  width: calc(21%);\n  background: #bfbfc340;\n}\n@media screen and (min-width: 980px) {\n  #FontWriter .ant-space-vertical {\n    min-width: 380px;\n    width: 65%;\n  }\n}\n@media screen and (max-width: 979px) {\n  #FontWriter {\n    width: 90vw;\n    margin: 0 auto;\n  }\n}\n#FontCanvas {\n  margin: auto;\n  border: 1px solid #b0a6a6ad;\n  border-radius: 5px;\n  display: block;\n}\n", ""]);
// Exports
module.exports = exports;
