import React from 'react';
// import { withRouter } from 'react-router';

// @withRouter
class Logout extends React.Component{
    constructor(props){
        super(props);
        window.localStorage.clear();
        window.location.href = '/';
    }
    render(){
        return;
    }
}

export default Logout;
