import React from 'react';
import BannerAnim, { Element } from 'rc-banner-anim';
import TweenOne from 'rc-tween-one';
import 'rc-banner-anim/assets/index.css';
import '../css/expand.css';

const BgElement = Element.BgElement;

class CAROUSEL extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            banner_ele:[],
        };
    };

    componentWillMount(){
        this.setState({banner_ele:this.props.banner_ele});
    };

    componentDidMount = async ()=>{

    };



    render() {
        return (

<BannerAnim prefixCls="ca" autoPlay autoPlaySpeed={10000}>


        {
            this.state.banner_ele.map(  (ele) => {
                const ani_pa = { y: 30,x:30, opacity: 0, type: 'from' };

                return <Element prefixCls="ca_mem" key={'ca_' + ele.n } >
                              <BgElement key={'bg_' + ele.n } className="bg" style={{
                                  background: "url("+require( "../static/img/" + ele.bg)+")",'background-size':'cover',
                                }}/>
                                    {
                                        ele.anList.map(an_ele => {
                                            return    <TweenOne className="ca_ani_ele" animation={{...ani_pa,...an_ele.ani}}>
                                                            <img className={an_ele.class} src={require( "../static/img/" + an_ele.path)} alt="#" style={{position:'absolute',...(window.innerWidth < 990 ? an_ele.css_m : an_ele.css)}}/>
                                                       </TweenOne>
                                        })
                                    }
                        </Element>
            })
        }

      </BannerAnim>


        );

    };
}

export default CAROUSEL;
