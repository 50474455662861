import React from 'react';
import '../css/basic.less'
import { Card } from 'antd';
import { Query } from './tools';

class Reg_check extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            info:'注册成功,即将跳转至主页...',
            redirectMark:false,
            redirectCountdown:5,
        }
    };

     async componentDidMount(){
        this.countDownStart();
        // const s = this.props.location.search.replace('?','').split('&');
        //
        // if(s.length != 3){
        //     this.setState({info:'发生未知错误'});
        // }else{
        //     const data = {
        //             target:'check',
        //             id:s[0],
        //             t:s[1],
        //             ic:s[2],
        //         };
        //     await Query('POST','/register',data,
        //         (response)=>{this.setState({info:response.data.info});this.countDownStart();},
        //         (response)=>this.setState({info:'发生未知错误'}));
        //
        // };

    };

     countDownStart = ()=>{
         this.setState({redirectMark:true});
         setInterval(()=>{
             if(this.state.redirectCountdown > 0){
                this.setState({redirectCountdown:this.state.redirectCountdown-1});
             }else{
                 window.location.href = '/';
             };
         },1000);
     };

  render() {
      return (
            <Card style={{ width: 300 , margin : '20% auto',padding:'10px',borderRadius:'5px' }}>
                <h3>{this.state.info}</h3>
                {
                    [0].map(()=>{
                        if(this.state.redirectMark){
                            return <h4>{this.state.redirectCountdown}秒后<a href='/' style={{color:'blue'}}>跳转</a>。。。</h4>
                        };
                    })
                }
            </Card>
      );
  }
}

export default Reg_check;
