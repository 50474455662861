import React from 'react';
import { Space,Menu,Dropdown,Button } from 'antd';
import { DownOutlined,UnorderedListOutlined} from '@ant-design/icons';
import { Link} from 'react-router-dom';
import '../css/basic.less'
import {debounce} from './tools';

class Nav extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            windowWidth : window.innerWidth,
            windowHeight : window.innerHeight,
        };
        this.nav = this.nav.bind(this);
    }

    componentWillMount = ()=>{
        this.whSetting = debounce(this.whSetting);
        this.whSetting();
        window.addEventListener('resize',this.whSetting); //监听窗口变化 作出响应式调整
    };

    componentWillUnmount(){
        window.removeEventListener('resize',this.whSetting);
    };

    whSetting = () =>
        this.setState({windowWidth:window.innerWidth,windowHeight:window.innerHeight});

    //导航栏的收缩/展开
    nav = ()=>{
        const nav = document.getElementById('nav_mob_extra');
        nav.classList.toggle('nav_open');
        nav.classList.toggle('nav_close');
        setTimeout(()=>{nav.classList.toggle('nav_open');
                        nav.classList.toggle('nav_close');},5000)
    };

    render() {

        const login_s = window.localStorage.getItem('lu',) > String((new Date()).valueOf()/1000) ;
        const name = window.localStorage.getItem('name',);
        if(!login_s)window.localStorage.clear();
        const user_s = window.localStorage.getItem('user_s',);

        const menu = (
              <Menu>
                  <Menu.Item >
                      <Link to={login_s ? "/logout":"../../login"}>{login_s ?  '登出' : '登录'}</Link>
                  </Menu.Item>

                  { user_s == 2 ?
                      <Menu.Item >
                          <Link to={'/admin/' + window.localStorage.getItem('id') }>管理员功能</Link>
                      </Menu.Item> : ''
                  }

                  <Menu.Item >
                  <Link to={login_s ? "../../cpwd/" + window.localStorage.getItem('id') :"../../register"}>{login_s ?  '修改密码' : '注册'}</Link>
                </Menu.Item>
              </Menu>
            );


      return (

          <Space>
              <a href="#" ><img src={require('../static/img/logo.png')} alt="#" style={{width:'120px',display:'block'}}/></a>

                  {
                     (()=>{

                         const nav_ele = [<Link exact={true} to='/index'>首页</Link>,
                                          <Link to='/newFont'>新建</Link>,
                                          <Link to={'/userFonts/'+window.localStorage.getItem('id')}>我的</Link>,
                                          <a href="https://pan.baidu.com/s/1ZcaNxuqsogJUZsBzL1BV4g"  target="_blank">下载</a>,
                                          <Dropdown overlay={menu} >
                                            <a className="ant-dropdown-link" onClick={e => e.preventDefault()} trigger={['click']}>{login_s?name:'请先登录'} <DownOutlined /></a>
                                          </Dropdown>,];

                         if(this.state.windowWidth > 990){
                             return <Space>
                                        {nav_ele}
                                    </Space>;
                         }else{
                             return [
                                 <Button style={{position:'absolute',right:'16px',top:'9px','z-index':'2'}} onClick={()=>this.nav()}><UnorderedListOutlined/></Button>,
                                 <Space style={{position:'absolute',left:'0px',top:'50px','overflow':'hidden',width:'100vw',background:'rgb(59 59 59 / 88%)','z-index':'1','padding-left':'20px','transition':'height .3s,visibility 0.3s','border-bottom': '2px solid #656565'}} id='nav_mob_extra' className='nav_close'>
                                    {nav_ele}
                                 </Space>
                             ];
                         }
                     })()
                  }

          </Space>

      );
  }
}

export default Nav;
