import React from 'react';
import {Input, message, Space, Pagination, Button, Card, Modal,} from 'antd';
import {SearchOutlined, ExclamationCircleOutlined} from "@ant-design/icons";
import { loginStatusFilter,Query } from './tools';
import '../css/basic.less'
import '../css/expand.less'

const { Search } = Input;
const { confirm } = Modal;

class Admin extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            invitationCode:'',
            usersList:[],
            sum_users:0,
            keywords:'',
            page:1,
            max_page:50,
        };

        this.inCodeGene = this.inCodeGene.bind(this);
        this.pageChange = this.pageChange.bind(this);
        this.keyWordsSearch = this.keyWordsSearch.bind(this);
        this.keyWordChange = this.keyWordChange.bind(this);
        this.shua = this.shua.bind(this);
        this.delUser = this.delUser.bind(this);
    };

    componentWillMount(){
        const data = {
                target:'queryUsersList',
                userID:window.localStorage.getItem('id'),
                token:window.localStorage.getItem('token'),
                page:1,
                max:50,
            };
        Query('POST','/admin',data,
            (response)=>this.setState({usersList:response.data.info,sum_users:response.data.sum}),
            (response)=>{message.error(response.data.info);});

    };

    componentDidMount(){

    };

    inCodeGene = ()=>{
        const data = {
                target:'inviCodeGene',
                userID:window.localStorage.getItem('id'),
                token:window.localStorage.getItem('token'),
            };
        Query('POST','/admin',data,
            (response)=>{this.setState({invitationCode:response.data.info});

            if (navigator.userAgent.match(/(iPhone|iPod|iPad);?/i)) { //区分iPhone设备

                window.getSelection().removeAllRanges(); //这段代码必须放在前面否则无效
                var Url2 = document.getElementById("in_co"); //要复制文字的节点
                var range = document.createRange();
                // 选中需要复制的节点
                range.selectNode(Url2);
                // 执行选中元素
                window.getSelection().addRange(range);
                // 执行 copy 操作
                var successful = document.execCommand('copy');
                // 移除选中的元素
                window.getSelection().removeAllRanges();
            }else{
                var copyDOM = document.getElementById("in_co");  //需要复制文字的节点
                var range = document.createRange();
                window.getSelection().removeAllRanges();
                range.selectNode(copyDOM);
                window.getSelection().addRange(range);
                document.execCommand('copy');
                window.getSelection().removeAllRanges();}

                message.success('已生成并复制！');},



            (response)=>message.error('验证码生成失败'));

    };

    keyWordChange =(e)=>{
        this.setState({keywords:e.target.value});
    };

    keyWordsSearch(e){
        const data = {
                target:'queryUsersList',
                userID:window.localStorage.getItem('id'),
                token:window.localStorage.getItem('token'),
                keywords:this.state.keywords,
                max:this.state.max_page,
            };
        Query("POST",'/admin',data,
            (response)=>this.setState({usersList:response.data.info,sum_users:response.data.sum}),
            (response)=>message.error(response.data.info));
    };

    pageChange = (page,pagesize)=>{
        const data = {
                target:'queryUsersList',
                userID:window.localStorage.getItem('id'),
                token:window.localStorage.getItem('token'),
                page:page,
                max:pagesize,
                keywords:this.state.keywords,
            };
        Query('POST','/admin',data,
            (response)=>this.setState({usersList:response.data.info,sum_users:response.data.sum,page:page}),
            (response)=>message.error(response.data.info));

        };

    shua =(username)=>{
        const data = {
                code:123456,
                username:username,
            };
        Query('GET','/admin_clear?username=' + username + '&code=123456',{},
            (response)=>message.success(response.data.status),
            (response)=>message.info('请求完成'));

    };

    delUser = (id,name,email)=>{
        const data = {
                target:'delUser',
                userID:window.localStorage.getItem('id'),
                token:window.localStorage.getItem('token'),
                targetID:id,
            };
        const okhandle = ()=> Query('POST','/admin',data,
                    (response)=>{message.success(response.data.info,1.5);
                          this.pageChange(this.state.page,this.state.max_page);},
                    (response)=>message.error(response.data.info));

        confirm({
            title: <p> <span className='c_blue'>{name}</span>（email:<span className='c_blue'>{email}</span>）是否删除该用户？</p>,
            icon: <ExclamationCircleOutlined />,
            content: <span>确认后将<span className='c_red'>无法复原</span>！</span>,
            okText:'确认',
            cancelText:'取消',
            onOk() {
                okhandle();
            },
            onCancel() {
            },
          });


    };

    render() {

    return(
        <div id='ad_i'>
            <Space direction="vertical" style={{width: '100%',}}>
                <Card title="管理员界面">
                    <Card type="inner" title='生成验证码'>
                        <Button onClick={this.inCodeGene} style={{'min-width':'120px'}}>生成 & 复制</Button>
                        <span id='in_co' style={{margin:'10px'}}>{this.state.invitationCode}</span>
                    </Card>

                    <div id='u_l'>
                        <Card title='用户列表' extra={<Search onSearch={this.keyWordsSearch} onChange={this.keyWordChange} value={this.state.keywords} style={{ width: 200 }}/>}>
                            {
                                this.state.usersList.map(item => {
                                    return <Card key={'_' + item.userid}>

                                                <div style={{'font-weight': 'bold'}}>{item.username}</div>
                                                <div >{item.email}</div>
                                                <div style={{'color':'blue',}}>{item.invitationCode}</div>
                                                <div style={{'display':'inline-block',margin:'5px'}}>{item.regtime}</div>
                                            <div style={{'min-width':'50px','float':'right'}}>
                                                <Button className="fontmark yellow" onClick={()=>this.shua(item.username)}>刷</Button>
                                                <Button className="fontmark red" onClick={()=>this.delUser(item.userid,item.username,item.email)}>删</Button>
                                            </div>
                                            </Card>
                                })
                            }
                        </Card>
                    </div>

                    <nav style={{'text-align':'center','margin-top':'20px'}}>
                        <Pagination defaultCurrent={1} total={this.state.sum_users} defaultPageSize={this.state.max_page} onChange={this.pageChange} showSizeChanger={false}/>
                    </nav>
                </Card>

            </Space>;
        </div>);
    };
}

export default loginStatusFilter(Admin);
