import React from 'react';
import { Query } from './tools';
import {Input, Space, Form, Button, message} from 'antd';
import { MinusCircleOutlined, PlusOutlined ,CloseOutlined ,DoubleRightOutlined} from '@ant-design/icons';
import {Link } from 'react-router-dom';
import '../css/basic.less'
import '../css/FL.less'

const { TextArea } = Input ;

class FontList extends React.Component {

    formRef = React.createRef();

    constructor(props) {
        super(props);
        this.state = {
            fonts:{},
            fontComplete:'',
            fontCustomize:[],
            cur_fonts:{},

            searchContent:'',
            search:false,

            confirm_dis:true,
        };
        this.formDel = {remove:'',add:'',fieldsName:[],};

        this.searchFilter = this.searchFilter.bind(this);
        this.remove = this.remove.bind(this);
    };

   componentWillMount(){

        let userid,fontid,fontset;
        if(!this.props.location.info){
            let pmp = this.props.match.params;
            userid = pmp.userid;
            fontid = pmp.fontid;
            fontset = parseInt(pmp.fontset);

        }else {
            let pli = this.props.location.info ;
            userid = pli.userid;
            fontid = pli.fontid;
            fontset = pli.fontset;
        };

        let c_f;
        let s_c = '';
        c_f = JSON.parse(window.localStorage.getItem('c_f'));
        for(let f in c_f){s_c += c_f[f]};

        window.onunload = ()=>{this.saveSearch()};

        let f_l;
        switch(fontset){
            case 0:
                f_l = 'f0';
                break;
            case 1:
                f_l = 'f1';
                break;
            case 2:
                f_l = 'f2';
                break;
        };
        let f = require(`../static/${f_l}.json`);

        const data = {
                target:'completeConfirm',
                token:window.localStorage.getItem('token'),
                userID:userid,
                fontID:fontid,
                fontset:fontset,
            };
        Query("POST",'/font',data,
            (response)=>{
                let fontComplete  = response.data.fontComplete;
                let fontCustomize = response.data.fontCustomize;
                console.log(response.data);
                this.setState({fonts:f,fontComplete:fontComplete,fontCustomize:fontCustomize,cur_fonts:c_f,searchContent:s_c});},
            (response)=>{let fontComplete  = '';
                let fontCustomize = [];
                this.setState({fonts:f,fontComplete:fontComplete,fontCustomize:fontCustomize,cur_fonts:c_f,searchContent:s_c});});

    };

   componentWillUnmount (){
       this.saveSearch();
       delete this.formRef;
   };

   searchFilter(e){
       if(e.target.value){
           let keys = Array.from(new Set(e.target.value.replace(' ','')));
           var fs  = Object.values(this.state.fonts);
           let fcs = Object.keys(this.state.fonts);
           keys = keys.map( key =>{
               if(fs.indexOf(key)>=0)return fs.indexOf(key);
           });

           let l = {};
           for(let key of keys){
               l[ fcs[key] ] = fs[key];
           };

           this.setState({ searchContent:e.target.value ,search : true ,cur_fonts : l, });
       }else {
           this.setState({ searchContent:e.target.value ,search : false});
       };
   };

   saveSearch=()=>{
       let c_f;
       if(this.state.searchContent){
           c_f = this.state.cur_fonts;
       }else {
           c_f = {};
       };

       window.localStorage.setItem('c_f',JSON.stringify(c_f));
   };

   remove = font => {

      let pmp = this.props.match.params;
      const data = {
                target:'customizeRemove',
                token:window.localStorage.getItem('token'),
                userID:pmp.userid,
                fontID:pmp.fontid,
                fontset:pmp.fontset,
                font:font,
            };
      const Loading = message.loading('请稍等...',0);
      Query("POST",'/font',data,
          (response)=>{
                    setTimeout(Loading,100);
                    setTimeout(message.success(response.data.info,1.5),1000);
                    for(let c of this.formDel.fieldsName){this.formDel.remove(0);};
                    this.setState({fontCustomize:response.data.fontCustomize});},
          (response)=>message.error(response.data.info));
   };


   add = values => {
      let pmp = this.props.match.params;
      const data = {
                target:'customizeAdd',
                token:window.localStorage.getItem('token'),
                userID:pmp.userid,
                fontID:pmp.fontid,
                fontset:pmp.fontset,
                font:JSON.stringify(values),
            };
      Query("POST",'/font',data,
          (response)=>{message.success(response.data.info);
                    for(let c of this.formDel.fieldsName){this.formDel.remove(0);};
                    this.setState({fontCustomize:response.data.fontCustomize});},
          (response)=>message.error(response.data.info));

    };


   render() {

      return (
          <div id='FontList'>
              <Space direction="vertical" style={{width: '100%',}}>

                  <div style={{padding:'20px 5% 0 5%'}}>
                        <TextArea rows={4} cols ={12} value={this.state.searchContent} onChange={this.searchFilter} ></TextArea>
                        <div className='c_red' style={{width:'99%'}}>输入文字可以快速匹配</div>
                  </div>

                  <div style={{'display':'flex','flex-wrap': 'wrap','margin':'auto','padding':'10px','background':'#ffffff40','border-radius':'5px','overflow-y': 'scroll','max-height': '300px','overflow-x': 'hidden','max-width': '1500px'}}>
                  {
                      (()=>{
                          const f = [];
                          const pli = this.props.location.info?this.props.location.info:this.props.match.params;
                          if(this.state.searchContent){var target = this.state.cur_fonts;}else {var target = this.state.fonts};

                          for(var key in target){
                              const backgroundColor = this.state.fontComplete.indexOf(target[key]) >= 0 ? '#7e7dff' : '';
                              const fontColor = backgroundColor ? '#ffffff' : 'gray';

                              if(typeof(target[key])=='string'){
                                  f.push(<span style={{margin:'2px','background':backgroundColor,'border-radius':'3px'}}>
                                            <Link style={{'color':fontColor,'margin':'3px'}} to={{pathname:'/FontWriter/'+pli.userid+'/'+pli.fontid+'-'+pli.fontset+'/'+key ,
                                                     info:{cur_fonts : this.state.cur_fonts, writting_font:target[key], fontcode:key , fonts:this.state.fonts ,fontid:pli.fontid ,id:pli.fontid,userid:pli.userid,fontset:pli.fontset,...this.props.location.info}}}>

                                                {target[key]}

                                            </Link>
                                        </span>);};
                          };
                          return f;
                      })()
                  }
                  </div>

                  <div style={{'width':'80%','margin':'20px 10% 20px 10%','overflow-y': 'scroll','max-height': '500px','overflow-x': 'hidden',}}>
                      <div>
                          <div style={{maigin:'10px'}}>自定义字段:</div>
                          {
                              this.state.fontCustomize.map(item=>{
                                  const pli = this.props.location.info?this.props.location.info:this.props.match.params;
                                  return <div key={'C_' + item} style={{margin:'2px',display:'inline-block',border:'1px solid #c2bcce','border-radius':'10px',padding:'2px',width:'100px',background:'#fbfbfb'}}>
                                            <Link style={{'margin':'3px'}} to={{pathname:'/FontWriter/'+pli.userid+'/'+pli.fontid+'-'+pli.fontset+'/' + '$' + item ,
                                                          info:{...this.props.location.info,cur_fonts : this.state.cur_fonts, writting_font:item, fontcode:'$'+item , fonts:this.state.fonts ,fontid:pli.fontid ,id:pli.fontid,userid:pli.userid,fontset:pli.fontset,}}}>
                                                {item}
                                            </Link>
                                            <a href="javascript:void(0)" style={{float:'right'}} onClick={()=>this.remove(item)}><CloseOutlined /></a>
                                        </div>
                              })
                          }
                      </div>

                      <Form name="Customize" onFinish={this.add} autoComplete="off">
                          <Form.List name="FC">
                            {(fields, { add, remove }) => {

                              if(this.formDel.remove.length == 0){this.formDel.remove = remove};
                              if(this.formDel.add.length == 0){this.formDel.add = add};

                              return (
                                <div>
                                  {fields.map(field => (
                                    <Space key={field.key} style={{ display: 'flex', margin: 0,  }} align="start">
                                      <Form.Item
                                        {...field}
                                        name={[field.name, 'fontCustomize']}
                                        fieldKey={[field.fieldKey, 'first']}
                                        rules={[{ required: true, message: '不能为空' },{pattern:'.*[^ \f\n\r\t\v].*',message: '不能全为空白'},
                                                {max:3, message: '内容不能超过3个字符'},]}
                                      >
                                        <Input placeholder="自定义内容" />
                                      </Form.Item>

                                      <MinusCircleOutlined
                                        onClick={() => {
                                          remove(field.name);
                                        }}
                                      />
                                    </Space>
                                  ))}

                                  <Form.Item>
                                     <Button type="primary" htmlType="submit" disabled={this.formDel.fieldsName.length == 0}>
                                      确定
                                     </Button>

                                    <Button
                                      onClick={() => {
                                        this.formDel.fieldsName.push(fields.name);
                                        add();
                                      }}
                                    >
                                      添加
                                    </Button>

                                  </Form.Item>
                                </div>
                              );
                            }}
                          </Form.List>

                     </Form>
                  </div>
              </Space>
          </div>
      );
  }
}

export default FontList;
