import React from 'react';
import {Button,message, Card, Space ,Breadcrumb, Modal,} from 'antd';
import '../css/basic.less';
import '../css/MF.less';
import { loginStatusFilter,Query } from './tools';
import {Link} from "react-router-dom";
import {EditOutlined, EyeFilled, FireOutlined , EyeInvisibleFilled , ExclamationCircleOutlined} from "@ant-design/icons";
import Canvas from "./Canvas";

const { confirm } = Modal;

class MyFont extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            fontsize:200,

            fonts:[],

        };
        this.fontUpgrade = this.fontUpgrade.bind(this);
    };

    componentWillMount(){

        const Fs = parseInt((window.innerWidth*0.95 - 30)/5);
        this.setState({fontsize:Fs <= 180 ? Fs : 180 });

        const data = {
                target:'myFont',
                userID:window.localStorage.getItem('id'),
                token:window.localStorage.getItem('token'),
            };
        Query('POST','/font',data,
             (response)=>this.setState({fonts:response.data.data}),
             (response)=>message.error(response.data.info));
    };

    fontUpgrade = (fontid,fontname,upset) => {
        const set_name = {0:'英文',1:'中文3702字',2:'中文7031字'};
        const data = {
                target:'upgrade',
                userID:window.localStorage.getItem('id'),
                token:window.localStorage.getItem('token'),
                fontID:fontid,
                upto:upset,
            };

        confirm({
                title: <span>是否要将字体“{fontname}”升级为<span className='c_blue'>“{set_name[upset]}”</span>？</span>,
                icon: <ExclamationCircleOutlined />,
                content: <span>确认升级后将<span className='c_red'>无法复原</span>！</span>,
                okText:'确认',
                cancelText:'取消',
                onOk() {
                    Query('POST','/font',data,
                         (response)=>{message.success(response.data.info);setTimeout(()=> window.location.reload(),1500);},
                         (response)=>message.error(response.data.info));
                },
                onCancel() {
                },
              });
    };

    fontOpen = (fontid,fontname) => {
        const data = {
                target:'open',
                userID:window.localStorage.getItem('id'),
                token:window.localStorage.getItem('token'),
                fontID:fontid,
            };

        confirm({
            title: <span>是否要将字体“ <span className='c_blue'>{fontname}</span>”公开？</span>,
                icon: <ExclamationCircleOutlined />,
                content: <span>确认后将<span className='c_red'>无法复原</span>！</span>,
                okText:'确认',
                cancelText:'取消',
                onOk() {
                    Query('POST','/font',data,
                         (response)=>{message.success(response.data.info);setTimeout(()=> window.location.reload(),1500);},
                         (response)=>message.error(response.data.info));
                },
                onCancel() {
                },
              });
    };

    fontDel = (fontid,fontname) => {
        const data = {
                target:'del',
                userID:window.localStorage.getItem('id'),
                token:window.localStorage.getItem('token'),
                fontID:fontid,
            };

        confirm({
            title: <span>是否要将字体“ <span className='c_blue'>{fontname}</span>”删除？</span>,
                icon: <ExclamationCircleOutlined />,
                content: <span>确认后将<span className='c_red'>无法复原</span>！</span>,
                okText:'确认',
                cancelText:'取消',
                onOk() {
                    Query('POST','/font',data,
                         (response)=>{message.success(response.data.info);setTimeout(()=> window.location.reload(),1500);},
                         (response)=>message.error(response.data.info));
                },
                onCancel() {
                },
              });
    };

    render() {

          return (
              <div id='mf'>
              <Space direction="vertical" style={{width: '100%',}}>
                  <Card title={<p><span style={{color:'blue'}}> {window.localStorage.getItem('name')} </span>创建的字体</p>}>
                    {
                        this.state.fonts.map(item=>{

                            let I = item;
                            I.fontid = item.id;

                            const titleNode = () => <Link to={{pathname:'/fontDetail/'+item.userid+'/'+item.id+'-'+item.fontset,info:I ,}}><EditOutlined/>{item.fontname}</Link>;
                            const set_name = {0:'英文',1:'中文3702字',2:'中文7031字'};
                            const FontUpGrade = item.fontset < 2 ? <Button className='blue c_white' onClick={()=>this.fontUpgrade(item.id,item.fontname,item.fontset+1)}>升级</Button> : '';
                            const beOpen = item.isopen !== 1 ? <Button className='green c_white' onClick={()=>this.fontOpen(item.id,item.fontname)}>公开</Button> : '';
                            // const delButton = <Button className='red c_white' onClick={()=>this.fontDel(item.id,item.fontname)}>删除</Button> ;
                            return(
                                        <Card type="inner"
                                            title={[titleNode(),
                                                    <div><Breadcrumb  separator="·">
                                                        <Breadcrumb.Item>{set_name[item.fontset]}</Breadcrumb.Item>
                                                        <Breadcrumb.Item>{item.isopen?<EyeFilled />:<EyeInvisibleFilled />}</Breadcrumb.Item>
                                                        <Breadcrumb.Item>{item.timecreate}</Breadcrumb.Item>
                                                    </Breadcrumb></div>]}
                                              extra={[FontUpGrade,beOpen]} key={'M_' + item.id}>

                                            <Canvas settings_parameter={{
                                                        'dom_id': 'canvas_' + item.id,
                                                        'dom_width': this.state.fontsize * 5,
                                                        'dom_height': this.state.fontsize,
                                                        'gcodes': item.gcodes,
                                                        offset_x: this.state.fontsize,
                                                        fontsize: this.state.fontsize,
                                                        resize: true,
                                                        cur_fonts: ' '
                                                    }}
                                                            style={{
                                                                width: '95%',
                                                                height: '200px',
                                                                margin: 'auto',
                                                                border: 'black 1px solid',
                                                                display:'inline-block',
                                                            }} onRef={(ref) => {
                                                        this.subCanvas = ref;
                                                        this.subCanvas.mode_set('d');
                                                    }}>
                                             </Canvas>
                                        </Card>

                                    )})
                    }
                    </Card>
                </Space>
              </div>
          );
    };
}

export default loginStatusFilter(MyFont);
