import React from 'react';
import {Form, Input, Button, Row, Col, Typography, message} from 'antd';
import { Query } from './tools';
import '../css/basic.less'
import '../css/Form.less'
import {EditOutlined, EyeFilled, FireOutlined , EyeInvisibleFilled} from "@ant-design/icons";

const { Title } = Typography;

class Cpwd extends React.Component {

    constructor(props) {
        super(props);
        this.state = {

        };

    };





    render() {

            const layout = {
              labelCol: { span: 8 },
              wrapperCol: { span: 10 },
            };
            const tailLayout = {
              wrapperCol: { offset: 8, span: 8 },
            };

            const onFinish = values => {
                const data = {
                        target:'cpwd',
                        userid:this.props.match.params.userid,
                        ...values
                    };
                Query("POST",'/register',data,
                    (response)=>{message.success(response.data.info,1.5);
                                 setTimeout(()=>{
                                    window.localStorage.clear();
                                    window.location.href = '/';
                                 },1500);},
                    (response)=>message.error(response.data.info,1.5)
                    );

            };

            const onFinishFailed = errorInfo => {
            };

          return (
          <Form
              {...tailLayout}
              name="login"
              className="form"
              ref={this.formRef}
              initialValues={{ remember: true }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
            >
          <nav style={{'text-align':'center'}} className='CommonForm'>

          <Row style={{'margin-bottom':'12px'}}>
              <Col span={12} offset={6}><Title level={3}>修改密码</Title></Col>
          </Row>

          <Form.Item {...layout}
            // label="用户名"
            name="username"
            rules={[{ required: true, message: '请输入用户名！'},
                    {pattern:'^[\u002d\u002e\u0040\u005f\u4E00-\u9FA5A-Za-z0-9]+$',message: '不支持特殊字符'},
                    {min:3, message: '用户名不能小于3个字符！'},
                    {max:30, message: '用户名不能超过30个字符！'}]}
          >
            <Input  placeholder='请输入用户名'/>
          </Form.Item>

          <Form.Item {...layout}
            // label="原密码 "
            name="password"
            rules={[{ required: true, message: '请输入原密码！' },
                    {pattern:'^[a-zA-Z0-9]*$',message: '只支持数字、英文'},
                    {min:6, message: '密码不能小于6个字符！'},
                    {max:16, message: '密码不能超过16个字符！'}]}
          >
            <Input.Password placeholder='请输入原密码'/>
          </Form.Item>

          <Form.Item {...layout}
            // label="新密码 "
            name="newPassword"
            rules={[{ required: true, message: '请输入原密码！' },
                    ({ getFieldValue }) => ({
                        validator(rule, value) {
                          if (!value || getFieldValue('password') !== value) {
                            return Promise.resolve();
                          }
                          return Promise.reject('新密码与原密码相同！');
                        },
                      }),
                    {pattern:'^[a-zA-Z0-9]*$',message: '只支持数字、英文'},
                    {min:6, message: '密码不能小于6个字符！'},
                    {max:16, message: '密码不能超过16个字符！'}]}
          >
            <Input.Password placeholder='请输入新密码'/>
          </Form.Item>

          <Form.Item {...layout}>
              <div>
                  <nav style={{'text-align':'center'}}>
                    <Button type="primary" htmlType="submit">
                      提交
                    </Button>
                  </nav>
              </div>
          </Form.Item>

          </nav>
        </Form>
          );
    };
}

export default Cpwd;
