import React from 'react';
import Fontshow from './Fontshow';
import Logout from "./Logout";
import Login from "./Login";
import Register from "./Register";
import Reg_check from "./Reg_check";
import Admin from "./Admin";
import Cpwd from "./Cpwd";
import Fpwd from "./Fpwd";
import NewFont from "./NewFont";
import Protocol from "./Protocol";
import FontWriter from "./FontWriter";
import MyFont from "./MyFont";
import FontDetail from "./FontDetail";
import FontList from "./FontList";
import V from "./V";

import { Route,Switch,Redirect } from 'react-router-dom';

class Middle extends React.Component {
constructor(props) {
        super(props);
    };

    render(){
        return(
            <div>
                {/*<Redirect from='/' exact to='/index' ></Redirect>*/}
                <Route path="/index" exact component={Fontshow} ></Route>
                <Route path="/" exact component={Fontshow} ></Route>
                <Route path="/logout" component={Logout} ></Route>
                <Route path="/login" component={Login} ></Route>
                <Route path="/register" component={Register} ></Route>
                <Route path="/protocol" component={Protocol} ></Route>
                <Route path="/reg_check" component={Reg_check} ></Route>
                <Route path="/admin/:userid" component={Admin} ></Route>
                <Route path="/cpwd/:userid" component={Cpwd} ></Route>
                <Route path="/fpwd" component={Fpwd} ></Route>
                <Route path="/newFont" component={NewFont} ></Route>
                <Route path="/userFonts/:userid" component={MyFont} ></Route>
                <Route path="/fontDetail/:userid/:fontid-:fontset" component={FontDetail} ></Route>
                <Route path="/fontList/:userid/:fontid-:fontset" component={FontList} ></Route>
                <Route path="/fontWriter/:userid/:fontid-:fontset/:fontcode" component={FontWriter} ></Route>
                <Route path="/v" component={V} ></Route>
            </div>
                );
    };

}

export default Middle;
