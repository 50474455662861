import React from 'react';
import axios from 'axios';
import { Form, Input, Button, Checkbox,Row,Col,Typography,message  } from 'antd';
import { Link } from 'react-router-dom';
import '../css/basic.less'
import '../css/Form.less'
import {Query} from "./tools";
import V from './V';
const { Title } = Typography;
axios.defaults.withCredentials = true;
class Login extends React.Component {

    formRef = React.createRef();

    constructor(props) {
        super(props);
        this.state = {
            code: '',
            codeLength: 4,
            fontSizeMin: 20,
            fontSizeMax: 22,
            backgroundColorMin: 240,
            backgroundColorMax: 250,
            colorMin: 10,
            colorMax: 20,
            lineColorMin: 40,
            lineColorMax: 180,
            contentWidth: 96,
            contentHeight: 32,
        };
    };

    componentWillMount(){
        this.canvas = React.createRef()
        const data = {
                target:'check',
                id:window.localStorage.getItem('id'),
                token:window.localStorage.getItem('token'),
            };
        Query("POST",'/login',data,
            (response)=>window.localStorage.clear(),
            {'001':(response)=>window.location.href = ''});

    };

    componentDidMount() {
        this.drawPic()
    };

    randomNum = (min, max) => {
        return Math.floor(Math.random() * (max - min) + min)
    };

    drawPic = () => {
        this.randomCode()
    };

    randomColor(min, max) {
        const r = this.randomNum(min, max);
        const g = this.randomNum(min, max);
        const b = this.randomNum(min, max);
        return `rgb(${r}, ${g}, ${b})`;
    };

    drawText(ctx, txt, i) {
        ctx.fillStyle = this.randomColor(this.state.colorMin, this.state.colorMax);
        const fontSize = this.randomNum(this.state.fontSizeMin, this.state.fontSizeMax);
        ctx.font = fontSize + 'px SimHei';
        const padding = 10;
        const offset = (this.state.contentWidth - 40) / (this.state.code.length - 1);
        let x = padding;
        if (i > 0) {
            x = padding + (i * offset)
        }
        let y = this.randomNum(this.state.fontSizeMax, this.state.contentHeight - 5);
        if (fontSize > 40) {
            y = 40
        }
        const deg = this.randomNum(-10, 10);
        ctx.translate(x, y);
        ctx.rotate(deg * Math.PI / 180);
        ctx.fillText(txt, 0, 0);
        ctx.rotate(-deg * Math.PI / 180);
        ctx.translate(-x, -y);
    };

    drawLine(ctx) {
        // 绘制干扰线
        for (let i = 0; i < 1; i++) {
            ctx.strokeStyle = this.randomColor(this.state.lineColorMin, this.state.lineColorMax);
            ctx.beginPath();
            ctx.moveTo(this.randomNum(0, this.state.contentWidth), this.randomNum(0, this.state.contentHeight));
            ctx.lineTo(this.randomNum(0, this.state.contentWidth), this.randomNum(0, this.state.contentHeight));
            ctx.stroke();
        };
    };

    drawDot(ctx) {
        for (let i = 0; i < 30; i++) {
            ctx.fillStyle = this.randomColor(0, 255)
            ctx.beginPath()
            ctx.arc(this.randomNum(0, this.state.contentWidth), this.randomNum(0, this.state.contentHeight), 1, 0, 2 * Math.PI)
            ctx.fill()
        }
    };

    reloadPic = () => {
        this.drawPic()
    };

    changeCode = e => {

        if (e.target.value.toLowerCase() !== '' && e.target.value.toLowerCase() !== this.state.code.toLowerCase()) {
            this.setState({
                showError: true
            });
        } else if (e.target.value.toLowerCase() === '') {
            this.setState({
                showError: false
            });
        } else if (e.target.value.toLowerCase() === this.state.code.toLowerCase()) {
            this.setState({
                showError: false
            });
        }
    };

    randomCode() {
        let random = '';
        const str = 'QWERTYUPLKJHGFDSAZXCVBNMqwertyupkjhgfdsazxcvbnm1234567890';
        for (let i = 0; i < this.state.codeLength; i++) {
            const index = Math.floor(Math.random() * 57);
            random += str[index];
        }
        this.setState({
            code: random
        }, () => {
            const canvas = this.canvas.current;
            const ctx = canvas.getContext('2d');
            ctx.textBaseline = 'bottom';
            ctx.fillStyle = this.randomColor(this.state.backgroundColorMin, this.state.backgroundColorMax);
            ctx.fillRect(0, 0, this.state.contentWidth, this.state.contentHeight);
            for (let i = 0; i < this.state.code.length; i++) {
                this.drawText(ctx, this.state.code[i], i);
            }
            this.drawLine(ctx);
            this.drawDot(ctx);
        })
    }

    render() {

    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 14 },
    };
    const tailLayout = {
      wrapperCol: { offset: 6, span: 12 },
    };

    const onFinish = values => {
        const data = values;
        Query('POST','/login',data,
            (response)=>{message.success(response.data.info,1.5);
                const itemKeyName = ['id','token','name','user_s','lu',];
                itemKeyName.map(key=>{window.localStorage.setItem(key,response.data[key])});
                setTimeout(()=>{window.location.href = '/'},2000);},
            (response)=>{message.error(response.data.info,1.5);
                this.formRef.current.resetFields();this.reloadPic();});

    };

    const onFinishFailed = errorInfo => {
    };

      return (
          <Form
              {...tailLayout}
              name="login"
              className="form"
              ref={this.formRef}
              initialValues={{ remember: true }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
            >
            <nav className='CommonForm'>
              <Row style={{'margin-bottom':'12px','text-align':'center'}}>
                  <Col span={12} offset={6}><Title level={3}>用户登录</Title></Col>
              </Row>

              <Form.Item {...layout}
                // label="用户名"
                name="username"
                rules={[{ required: true, message: '请输入用户名！'},]}
              >
                  <div>
                <Input  placeholder='请输入用户名'/>
                      </div>
              </Form.Item>

              <Form.Item {...layout}
                // label="密码 "
                name="password"
                rules={[{ required: true, message: '请输入密码！' },]}
              >
                  <div>
                <Input.Password placeholder='请输入密码'/>
                      </div>
              </Form.Item>

              <Form.Item
                {...layout}
                 name='vc'
                 rules={[{ required: true, message: '请输入校验码!' },
                         {
             validator: (rule, value, callback) => {
             if (value) {
             if(value.toLowerCase()===this.state.code.toLowerCase()){
                  callback()
                  this.setState({
                     sendcode: value,
                  })
             } else {
                 callback('请输入正确的验证码')
                }
            } else {
               callback()
              }
            }
           },
                   ]}
              >
                <div>
                   <Input
                         style={{'width':'calc(100% - 160px)'}}
                         onChange={this.changeCode}
                         placeholder="请输入校验码"
                     />
                    <canvas
                        style={{margin:'0 0 -10px 10px'}}
                        onClick={this.reloadPic}
                        ref={this.canvas}
                        width='100'
                        height='32'>
                    </canvas>
                    <a  style={{'display':'inline-block',width:'50px'}} href="javascript:void(0)" onClick={this.reloadPic}>换一张</a>
                </div>
              </Form.Item>

              <Form.Item {...layout}>
                  <div>
                      <nav className='BN'>
                        <Button type="primary" htmlType="submit" >
                          登录
                        </Button>

                        <a href="javascript:void(0)">
                            <Link to={'/register'}>注册</Link>
                        </a>

                        <a href="javascript:void(0)">
                            <Link to={'/fpwd'}>忘记密码</Link>
                        </a>
                      </nav>
                  </div>

              </Form.Item>
            </nav>
        </Form>

      );
  }
}

export default Login;
