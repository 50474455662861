import React from 'react';

import '../css/basic.css';



function Bottom() {
  return (
        <div style={{height:'20px','margin-top':'-20px','padding-left':'30px'}}>
            <p>Copyright © 2024 LichTech Inc.  	<a href='https://beian.miit.gov.cn/' >沪ICP备17030524号</a></p>
        </div>

  );
}

export default Bottom;
