import React from 'react';

function Protocol() {
  return (
    <div style={{margin:'30px'}}>
        <p>&nbsp;</p>
        <p><b>本用户协议由以下五部分构成，分为版权声明、隐私声明、免责声明、合约变更与法律适用：</b></p>
        <p>&nbsp;</p>
        <p><b>零潮网络科技原生笔迹书写平台(Lich.ltd)版权声明：</b></p>
        <ol>
          <li>1、零潮网络科技原生笔迹书写平台(Lich.ltd)上的所有提供使用的字体和资源均为字体或资源作者提供和分享而来，仅供购买零潮网络科技有限公司生产的智能仿生打印机的用户使用。如有侵犯您的版权，请联系我们，本站将在合理时间内改正。</li>
          <li>2、访问零潮网络科技原生笔迹书写平台(Lich.ltd)的用户应了解，零潮网络科技原生笔迹书写平台(Lich.ltd)对其提供使用的字体及其他资源不拥有任何权利，相关字体或其他资源的版权归该资源的合法拥有者所有。</li>
          <li>3、零潮网络科技原生笔迹书写平台(Lich.ltd)不保证其提供的字体或其他资源的准确性、安全性和完整性；使用时请自行检查是否带有病毒，同时零潮网络科技原生笔迹书写平台(Lich.ltd)也不承担用户因使用这些字体或资源对自己和他人造成任何形式的损失或伤害。</li>
          <li>4、未经零潮网络科技原生笔迹书写平台(Lich.ltd)的明确许可，任何人不能盗链本站的字体或其他资源；不得复制或仿照本网站或者在非零潮网络科技原生笔迹书写平台(Lich.ltd)所属服务器上建立镜像，零潮网络科技原生笔迹书写平台(Lich.ltd)对其自行开发的或和他人共同开发的所有内容、技术手段和服务拥有全部知识产权，任何人不得侵害或破坏，也不得擅自使用。</li>
          <li>5、本网站以下内容亦不可任意转载：</li>
          <li>a.本网站所指向的非本网站内容的相关链接内容；</li>
          <li>b.已做出不得转载或未经许可不得转载声明的内容；</li>
          <li>c.未由本网站署名或本网站引用、转载的他人作品等非本网站版权内容；</li>
          <li>d.本网站中特有的图形、标志、页面风格、编排方式、程序等；</li>
          <li>e.本网站中必须具有特别授权或具有注册用户资格方可知晓的内容；</li>
          <li>f.其他法律不允许或本网站认为不适合转载的内容。</li>
          <li>6、对不遵守本声明或其他违法、恶意使用本网站内容者，本网站保留追究其法律责任的权利。</li>
        </ol>
        <p>&nbsp;</p>
        <p><b>零潮网络科技原生笔迹书写平台(Lich.ltd)隐私声明：</b></p>
        <ol>
          <li>1、本网站将尽可能采取一切措施保护用户数据及个人信息资料安全，只根据用户在网站上的行为指示来分发用户的信息，不会向任何第三方公开或共享用户的具体数据。</li>
          <li>2、本网站保留使用汇总统计性信息的权利，在不透露单个用户隐私资料的前提下，本网站有权对整个用户数据库进行分析并对用户数据库进行商业上的利用。</li>
          <li>3、就下列相关事宜的发生，零潮网络科技原生笔迹书写平台(Lich.ltd)不承担任何法律责任：</li>
          <li>a.零潮网络科技原生笔迹书写平台(Lich.ltd)根据法律规定或相关政府的要求提供您的个人信息；</li>
          <li>b.由于您将用户密码告知他人或与他人共享注册帐户，由此导致的任何个人 信息的泄漏，或其他非因零潮网络科技原生笔迹书写平台(Lich.ltd)原因导致的个人信息的泄漏；</li>
          <li>c.任何第三方根据零潮网络科技原生笔迹书写平台(Lich.ltd)各服务条款及声明中所列明的情况使用您的个人信息，由此所产生的纠纷；</li>
          <li>d.任何由于黑客攻击、电脑病毒侵入或政府管制而造成的暂时性网站关闭；</li>
          <li>e.因不可抗力导致的任何后果；</li>
          <li>f.零潮网络科技原生笔迹书写平台(Lich.ltd)在各服务条款及声明中列明的使用方式或免责情形。</li>
          <li>4、零潮网络科技原生笔迹书写平台(Lich.ltd)会不时更新本隐私权声明。更新时，我们还会修改隐私权声明顶部的&ldquo;最近更新&rdquo;的日期。</li>
        </ol>
        <p>&nbsp;</p>
        <p><b>零潮网络科技原生笔迹书写平台(Lich.ltd)免责声明：</b></p>
        <ol>
          <li>1、本网站将尽最大努力保障软件平台的连续可靠运行，但鉴于网络服务的特殊性，本网站对可能发生的网络服务的中断或终止所可能导致的一切损失，不承担任何法律责任及经济赔偿。</li>
          <li>2、本网站将尽最大努力保障客户数据的安全备份，但无法承诺100%的数据恢复，对因数据丢失带来的任何直接或间接损失不承担任何责任。</li>
          <li>3、在发生需要从备份文件中恢复数据的情形时，本网站通常需要4小时，最长48小时完成，在此时间范围内的数据恢复视作服务是连续的。</li>
          <li>4、对于因不可抗力或本网站不能控制的原因造成的网络服务中断和长时间终止，本网站不承担任何责任，但将尽力减少因此而给用户造成的损失和影响。</li>
        </ol>
        <p>&nbsp;</p>
        <p><b>零潮网络科技原生笔迹书写平台(Lich.ltd)合约变更: </b></p>
        <ol>
          <li>1、本网站有权随时对本合约的条款进行修订，一旦本合约的内容发生变更，本网站将会在修订生效日前一个工作日更新。</li>
          <li>2、如用户继续使用网络服务，则视为用户接受本网站对合约相关条款所做的修订。 法律适用</li>
        </ol>
        <p>&nbsp;</p>
        <p><b>零潮网络科技原生笔迹书写平台(Lich.ltd)法律适用</b></p>
        <ol>
          <li>1、本合约的订立、执行和解释以及争议的解决均应适用中华人民共和国法律并受中国法院管辖。</li>
          <li>2、协议双方就本合约内容或其执行发生任何争议，应首先力争友好协商解决；协商不成时，任何一方均可向零潮网络科技原生笔迹书写平台(Lich.ltd)所在的人民法院提起诉讼。</li>
        </ol>
    </div>
  );
};

export default Protocol;
