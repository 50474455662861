import React, {Component} from 'react';
import {message} from 'antd';
import Qs from 'qs';
import axios from "axios";

const loginStatusFilter = (WrappedComponent) =>
    class extends Component {
        componentWillMount(){
            if(!window.localStorage.getItem('id')){
                alert('请先登录！');
                window.localStorage.clear();
                window.location.hash = '#/login';
            };
        };
        render(){
            return <WrappedComponent  {...this.props} />
        };
    };

const loginStatusCheck = (WrappedComponent) =>
    class extends Component {
        componentWillMount(){
            if(!window.localStorage.getItem('id')){
                alert('请先登录！');
                window.localStorage.clear();
                window.location.hash = '#/login';
            };
        };
        render(){
            return <WrappedComponent  {...this.props} />
        };
    };

const isMobile = ()=>{
	if( navigator.userAgent.match(/Android/i)
		|| navigator.userAgent.match(/webOS/i)
		|| navigator.userAgent.match(/iPhone/i)
		|| navigator.userAgent.match(/iPad/i)
		|| navigator.userAgent.match(/iPod/i)
		|| navigator.userAgent.match(/BlackBerry/i)
		|| navigator.userAgent.match(/Windows Phone/i)
	)return true;
	return false;
};

//用于发送请求 method : GET/POST;url : string;data : object;responseHandle : fun(..){..};
const Query = (method,url,data,successHandle,failHandle = ()=>{})=> {
    axios({
        method: method,
        url: url,
        data: Qs.stringify(data),
    })
        .then((response) => {
            responseHandle(response,successHandle,failHandle);
        });
    };

const responseHandle = (response,successHandle,failHandle)=>{
    if(response.data.status == '300' & response.status == 200){
        successHandle(response);
    }else if(typeof (failHandle) == 'object'){
        failHandle(response.data.status)();
    }else{
        failHandle(response);
    };
};

/**
 ** @desc 函数防抖 *
 *  @param func 函数 *
 *  @param wait 延迟执行毫秒数
 *  * @param immediate true 表立即执行，false 表非立即执行
 *  */

const debounce = (func,wait = 300 ,immediate = false)=> {
    var timeout;

    return function () {
        let context = this;
        let args = arguments;

        if (timeout) clearTimeout(timeout);
        if (immediate) {
            var callNow = !timeout;
            timeout = setTimeout(() => {
                timeout = null;
            }, wait)
            if (callNow) func.apply(context, args)
        }
        else {
            timeout = setTimeout(function(){
                func.apply(context, args)
            }, wait);
        }
    }
};

/**
 * @desc 函数节流
 * @param func 函数
 * @param wait 延迟执行毫秒数
 * @param type 1 表时间戳版，2 表定时器版
 */
const throttle = (func, wait = 300 ,type= 1)=> {
    if(type===1){
        var previous = 0;
    }else if(type===2){
        var timeout;
    }
    return function() {
        let context = this;
        let args = arguments;
        if(type===1){
            let now = Date.now();

            if (now - previous > wait) {
                func.apply(context, args);
                previous = now;
            }
        }else if(type===2){
            if (!timeout) {
                timeout = setTimeout(() => {
                    timeout = null;
                    func.apply(context, args)
                }, wait)
            }
        }
    }
};

export {
    loginStatusFilter,
    loginStatusCheck,
    isMobile,
    debounce,
    throttle,
    Query,
}
