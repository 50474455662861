import React from 'react';
import {Form, Input, Button, Row, Col, Typography, message} from 'antd';
import { Query } from './tools';
import '../css/basic.less'
import '../css/Form.less'
const { Title } = Typography;

class Register extends React.Component {

  constructor(props) {
        super(props);
        this.state={
            code: '',
            codeLength: 4,
            fontSizeMin: 28,
            fontSizeMax: 30,
            backgroundColorMin: 240,
            backgroundColorMax: 250,
            colorMin: 10,
            colorMax: 20,
            lineColorMin: 40,
            lineColorMax: 180,
            contentWidth: 108,
            contentHeight: 40,

            sub_dis:false,
            cd:5,

            mailConfirm:'邮箱确认',
        }
    };

  componentWillMount(){
      this.canvas = React.createRef();
  };

  componentDidMount() {
    this.drawPic()
  };

  cdStart =()=>{
     this.setState({sub_dis:true,cd:5});

     this.timer = setInterval(()=>{
         if(this.state.cd >= 0) {
             this.setState({cd: this.state.cd - 1, sub_dis: true,mailConfirm:'(' + this.state.cd + ')'  });
         }else {
             clearInterval(this.timer)
             this.setState({cd:5,sub_dis:false,mailConfirm:'邮箱确认' });
         };
     },1000);

    };

  sendMailConfirm =()=>{
      const data = this.refs.myForm.getFieldValue();
      var reg = /^([a-zA-Z]|[0-9])(\w|\-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/;

      if(!data.mail && !reg.test(data.mail)){
          this.refs.myForm.validateFields(['mail']);
      }else {
          Query("POST",'/register',
              {
                  target:'confirmMail',
                  username:data.username,
                  mail:data.mail,
              },
            (response)=>{message.success(response.data.info,1);this.cdStart();},
            (response)=>message.error(response.data.info,1.5));
      };

  };

  randomNum = (min, max) => {
        return Math.floor(Math.random() * (max - min) + min)
    }

  drawPic = () => {
        this.randomCode()
    }

  randomColor(min, max) {
        const r = this.randomNum(min, max);
        const g = this.randomNum(min, max);
        const b = this.randomNum(min, max);
        return `rgb(${r}, ${g}, ${b})`
    }

  drawText(ctx, txt, i) {
        ctx.fillStyle = this.randomColor(this.state.colorMin, this.state.colorMax)
        const fontSize = this.randomNum(this.state.fontSizeMin, this.state.fontSizeMax)
        ctx.font = fontSize + 'px SimHei'
        const padding = 10;
        const offset = (this.state.contentWidth - 40) / (this.state.code.length - 1)
        let x = padding;
        if (i > 0) {
            x = padding + (i * offset)
        }
        let y = this.randomNum(this.state.fontSizeMax, this.state.contentHeight - 5)
        if (fontSize > 40) {
            y = 40
        }
        const deg = this.randomNum(-10, 10)
        ctx.translate(x, y)
        ctx.rotate(deg * Math.PI / 180)
        ctx.fillText(txt, 0, 0)
        ctx.rotate(-deg * Math.PI / 180)
        ctx.translate(-x, -y)
    }

  drawLine(ctx) {
        for (let i = 0; i < 1; i++) {
            ctx.strokeStyle = this.randomColor(this.state.lineColorMin, this.state.lineColorMax)
            ctx.beginPath()
            ctx.moveTo(this.randomNum(0, this.state.contentWidth), this.randomNum(0, this.state.contentHeight))
            ctx.lineTo(this.randomNum(0, this.state.contentWidth), this.randomNum(0, this.state.contentHeight))
            ctx.stroke()
        }
    }

  drawDot(ctx) {
        for (let i = 0; i < 30; i++) {
            ctx.fillStyle = this.randomColor(0, 255)
            ctx.beginPath()
            ctx.arc(this.randomNum(0, this.state.contentWidth), this.randomNum(0, this.state.contentHeight), 1, 0, 2 * Math.PI)
            ctx.fill()
        }
    }

  reloadPic = () => {
        this.drawPic()

    }

  changeCode = e => {

        if (e.target.value.toLowerCase() !== '' && e.target.value.toLowerCase() !== this.state.code.toLowerCase()) {
            this.setState({
                showError: true
            })
        } else if (e.target.value.toLowerCase() === '') {
            this.setState({
                showError: false
            })
        } else if (e.target.value.toLowerCase() === this.state.code.toLowerCase()) {
            this.setState({
                showError: false
            })
        }
    }

  randomCode() {
        let random = ''
        const str = 'QWERTYUPLKJHGFDSAZXCVBNMqwertyupkjhgfdsazxcvbnm1234567890'
        for (let i = 0; i < this.state.codeLength; i++) {
            const index = Math.floor(Math.random() * 57);
            random += str[index];
        }
        this.setState({
            code: random
        }, () => {
            const canvas = this.canvas.current;
            const ctx = canvas.getContext('2d')
            ctx.textBaseline = 'bottom'
            // 绘制背景
            ctx.fillStyle = this.randomColor(this.state.backgroundColorMin, this.state.backgroundColorMax)
            ctx.fillRect(0, 0, this.state.contentWidth, this.state.contentHeight)
            // 绘制文字
            for (let i = 0; i < this.state.code.length; i++) {
                this.drawText(ctx, this.state.code[i], i)
            }
            this.drawLine(ctx)
            this.drawDot(ctx)
        })
    }

  render() {


  const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 14 },
    };
    const tailLayout = {
      wrapperCol: { offset: 6, span: 12 },
    };

    const onFinish = values => {

        this.drawPic();

        const data = values;
        Query("POST",'/register',data,
            (response)=>{setTimeout(()=>window.location.href = '/#/Reg_check',1000)},
            (response)=>{message.error(response.data.info,1.5);this.reloadPic();});
    };

    const onFinishFailed = errorInfo => {
    };

      return (
          <Form
              {...tailLayout}
              name="register"
              ref="myForm"
              className="form"
              initialValues={{ remember: true }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
            >
          <nav style={{'text-align':'center'}} className='CommonForm'>

          <Row style={{'margin-bottom':'12px'}}>
              <Col span={12} offset={6}><Title level={3}>用户注册</Title></Col>
          </Row>

          <Form.Item {...layout}
            name="username"
            rules={[{ required: true, message: '请输入用户名！' },
                    {pattern:'^[\u002d\u002e\u0040\u005f\u4E00-\u9FA5A-Za-z0-9]+$',message: '不支持特殊字符'},
                    {min:3, message: '用户名不能小于3个字符！'},
                    {max:30, message: '用户名不能超过30个字符！'}]}
          >
            <Input  placeholder='请输入用户名'/>
          </Form.Item>

          <Form.Item {...layout}
            name="password"
            rules={[{ required: true, message: '请输入密码！' },
                    {pattern:'^[a-zA-Z0-9]*$',message: '只支持数字、英文'},
                    {min:6, message: '密码不能小于6个字符！'},
                    {max:16, message: '密码不能超过16个字符！'}]}
          >
            <Input.Password placeholder='请输入密码'/>
          </Form.Item>

          <Form.Item {...layout}
            // label="确认密码 "
            name="configPassword"
            rules={[{ required: true, message: '请输入密码！' },
                    ({ getFieldValue }) => ({
                        validator(rule, value) {
                          if (!value || getFieldValue('password') === value) {
                            return Promise.resolve();
                          }
                          return Promise.reject('两次密码不一致！');
                        },
                      }),
                    {pattern:'^[a-zA-Z0-9]*$',message: '只支持数字、英文'},
                    {min:6, message: '密码不能小于6个字符！'},
                    {max:16, message: '密码不能超过16个字符！'}]}
          >
            <Input.Password placeholder='确认密码'/>
          </Form.Item>

          <Form.Item {...layout}
            name="mail"
            rules={[ {
            validator: async (_, mail) => {
              const reg = /^([a-zA-Z]|[0-9])(\w|\-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/;
              if (!mail) {
                return Promise.reject(new Error('请输入邮箱！'));
              }else if(!reg.test(mail)){
                return Promise.reject(new Error('邮箱格式有误！'));
                }
            },
          }]}
          >
              <div>
                  <Input style={{width:'75%'}}  placeholder='邮箱(用于验证)'/>
                  <a disabled={this.state.sub_dis} style={{display:'inline-block',width:'21%','margin-left':'4%'}} onClick={ () => this.sendMailConfirm() }>{this.state.mailConfirm}</a>
              </div>
          </Form.Item>

          <Form.Item {...layout}
            name="mail_p"
            rules={[{ required: true, message: '请输入邮箱验证码！' }]}
          >
            <Input  placeholder='邮箱验证码'/>
          </Form.Item>

           <Form.Item {...layout}
            name="invitationCode"
            rules={[{ required: true, message: '请输入邀请码！' }]}
          >
            <Input  placeholder='邀请码'/>
          </Form.Item>

        <Form.Item
            {...layout}
             name='vc'
             rules={[{ required: true, message: '请输入校验码!' },
                     {
         validator: (rule, value, callback) => {
         if (value) {
         if(value.toLowerCase()===this.state.code.toLowerCase()){
              callback();
              this.setState({
                 sendcode: value,
              })
         } else {
             callback('请输入正确的验证码')
            }
        } else {
           callback()
          }
        }
       },
               ]}
          >
            <div>
               <Input
                     style={{'width':'calc(100% - 160px)'}}
                     onChange={this.changeCode}
                     placeholder="请输入校验码"
                 />
                <canvas
                    style={{margin:'0 0 -10px 10px'}}
                    onClick={this.reloadPic}
                    ref={this.canvas}
                    width='100'
                    height='32'>
                </canvas>
                <a  style={{'display':'inline-block',width:'50px'}} href="javascript:void(0)" onClick={this.reloadPic}>换一张</a>
            </div>
          </Form.Item>

          <Form.Item {...layout}>
            <a href="#/protocol" target="_blank">提交注册等于同意本网站协议 点击查看</a>
          </Form.Item>

          <Form.Item {...layout}>
              <div>
                  <nav style={{'text-align':'center'}}>
                    <Button type="primary" htmlType="submit">
                      注册
                    </Button>
                    {/*<span style={{'display':this.state.sub_dis ? 'inline' : 'none'}}>*/}
                        {/*{this.state.cd}秒后可再次发送*/}
                    {/*</span>*/}
                  </nav>
              </div>
          </Form.Item>
                  </nav>
        </Form>
      );
  }
}

export default Register;
