import React from 'react';
import Canvas from './Canvas'
import {Input, message, Space, Select, Button } from 'antd';
import { DoubleLeftOutlined,DoubleRightOutlined,RollbackOutlined,YoutubeOutlined,CloseOutlined,HistoryOutlined,EditOutlined,FormOutlined  } from '@ant-design/icons';
import TweenOne from 'rc-tween-one';
import { loginStatusFilter,Query } from './tools';
import '../css/basic.css';
import '../css/expand.css';



const { Option } = Select;

class FontWriter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            settings_parameter: {
                dom_id:'FontCanvas',
                dom_width:320,
                dom_height:320,
                fontsize:320,
            },
            cur_bg:0,
            bg:[],
            settings_parameter_sub:{
                dom_id:'FontCanvasSub',
                dom_width:window.innerWidth * 0.65 - 40 > 800 ? window.innerWidth * 0.65 - 40 : 800  ,
                dom_height:70,
                fontsize:35,
                offset_x:0,
                gcodes:[],
                number_order: true,
            },
            info_s : {},
            fn:{0:178,1:3702,2:7031},

            cur_fonts:[],

            del_font:-1,
            del_button_disable : true,

            isloading:false,
        };
    };

    componentWillMount(){
        this.setState({isloading:true});
        let userid,fontid,fontset,font,fontcode,c_f;

        if(this.props.location.info){
            let pli = this.props.location.info ;
            userid = pli.userid;
            fontid = pli.fontid;
            fontset = pli.fontset;

            if(pli.fontcode.indexOf('$') == 0){
                fontcode = 0;
                font = pli.fontcode;
            }else {
                fontcode = pli.fontcode;
                font = pli.writting_font;
            };

        }else{
            let pmp = this.props.match.params;
            let f_l;
            fontset = parseInt(pmp.fontset);
            switch(fontset){
                case 0:
                    f_l = 'f0';
                    break;
                case 1:
                    f_l = 'f1';
                    break;
                case 2:
                    f_l = 'f2';
                    break;
            };
            let f = require(`../static/${f_l}.json`);

            userid = pmp.userid;
            fontid = pmp.fontid;

            if(pmp.fontcode.indexOf('$') == 0){
                fontcode = 0;
                font = pmp.fontcode;
            }else {
                fontcode = pmp.fontcode;
                font = f[fontcode];
            };
        };

        const data = {
                target:'queryFont',
                token:window.localStorage.getItem('token'),
                userID:userid,
                fontID:fontid,
                fontset:fontset,
                font:font,
                fontcode:fontcode,
            };
        Query("POST",'/font',data,
            (response)=>{
                    let infos = '';

                    if(response.data.gcodes){
                        if(!this.props.location.info){
                            infos = this.infosGenerate();
                            this.setState({info_s:infos});
                        }else {
                            infos = this.props.location.info;
                            this.setState({info_s:infos});
                        };
                        let fontCount = infos.writting_font.length;
                        if(fontCount > 1){
                            const CharLen = ((f)=>{let BC_case = 0;for(var i = 0; i<f.length; i++ ){if(f.charCodeAt(i)>128){BC_case += 2 }else {BC_case += 1};};return BC_case;})(infos.writting_font);
                            const DW = window.innerWidth > 320 ? (window.innerWidth > 979 ? 650 : parseInt(window.innerWidth*0.8-20)) : parseInt(window.innerWidth*0.95-20);
                            const fontsize = DW/CharLen/100*250;

                            const sp = Object.assign({},this.state.settings_parameter,{dom_width:DW,dom_height:fontsize,fontsize:fontsize});
                            this.setState({settings_parameter:sp});
                        };

                        const sps = Object.assign({},this.state.settings_parameter_sub,{gcodes: response.data.gcodes,offset_x: 10 + infos.writting_font.length*35});
                        this.setState({settings_parameter_sub:sps});

                        this.setState({isloading:false});
                    };

                    this.setState({bg:response.data.b_c});
                    this.bgChange( window.localStorage.getItem('bg_d') ? parseInt(window.localStorage.getItem('bg_d')) : 0 );
                    },

            (response)=>message.error(response.info)
            );

    };

    componentDidMount(){
        let c_f;
        c_f = (()=>{
            if(window.localStorage.getItem('c_f')) {
                let fs = JSON.parse(window.localStorage.getItem('c_f'));
                let l = [];
                for (let f in fs) {
                    l.push({'fontcode': f, 'font': fs[f]});
                };
                return l;
            }else {
                return '';
            };
        })();
        if(c_f){
            this.setState({cur_fonts:c_f});
        }else {
            this.setState({cur_fonts:{}});
        };
    };

    infosGenerate(){

        let pmp = this.props.match.params;

        let f_l,fc_max,fc_min,fontcode,font;
        let fontset = parseInt(pmp.fontset);
        switch(fontset){
            case 0:
                f_l = 'f0';
                fc_min = 6765;
                fc_max = 7266;
                break;
            case 1:
                f_l = 'f1';
                fc_min = 1;
                fc_max = 7265;
                break;
            case 2:
                f_l = 'f2';
                fc_min = 1;
                fc_max = 7329;
                break;
        };
        let f = require(`../static/${f_l}.json`);

        if(pmp.fontcode.indexOf('$') == 0){
            fontcode = 0;
            font = pmp.fontcode.split('$')[1];
        }else {
            fontcode = pmp.fontcode;
            font = f[fontcode];
        };

        return {
            userid:parseInt(pmp.userid),
            id:parseInt(pmp.fontid),
            fontid:parseInt(pmp.fontid),
            fontset:parseInt(pmp.fontset),
            fontcode:fontcode,
            writting_font:font,
            fonts:f,
            fc_min:fc_min,
            fc_max:fc_max,
            cur_fonts:[],
        };

    };

    previous=()=>{

        if(  this.state.cur_fonts.length == 0 ){
            let fc;
            let i_fc = parseInt(this.state.info_s.fontcode);

            while (1) {
                if (i_fc - 1 < this.state.info_s.fc_min) {
                    fc = this.state.info_s.fc_max;
                    break};

                if( this.state.info_s.fonts.hasOwnProperty(i_fc - 1) ){
                    fc = i_fc - 1;
                    break}else{
                    i_fc --;};
                };
            // const fc = parseInt(this.state.info_s.fontcode) == this.state.fn[this.state.info_s.fontset] ? this.state.fn[this.state.info_s.fontset] : parseInt(this.state.info_s.fontcode) - 1;
            window.location.href  =  '#/FontWriter/'+this.state.info_s.userid+'/'+this.state.info_s.id+'-'+this.state.info_s.fontset+'/'+fc;
            window.location.reload();
        }else{
            let c_f_index = 0;
            let n_f_index;
            for(let f in this.state.cur_fonts){
                if(this.state.cur_fonts[f].fontcode == this.state.info_s.fontcode){
                    c_f_index = parseInt(f);
                    break;
                };
            };

            n_f_index = c_f_index - 1 < 0 ? this.state.cur_fonts.length - 1 : c_f_index - 1;
            window.location.href  =  '#/FontWriter/'+this.state.info_s.userid+'/'+this.state.info_s.id+'-'+this.state.info_s.fontset+'/'+this.state.cur_fonts[n_f_index].fontcode;
            window.location.reload();

        };
    };

    next=()=>{
        if( this.state.cur_fonts.length == 0 ){
            let fc;
            let i_fc = parseInt(this.state.info_s.fontcode);
            while (1) {
                if (i_fc + 1 > this.state.info_s.fc_max) {
                    fc = this.state.info_s.fc_min;
                    break};

                if( this.state.info_s.fonts.hasOwnProperty(i_fc + 1) ){
                    fc = i_fc + 1;
                    break}else{
                    i_fc ++;};
                };
            // let fc = parseInt(this.state.info_s.fontcode) == this.state.fn[this.state.info_s.fontset] ? 1 : parseInt(this.state.info_s.fontcode) + 1;
            window.location.href  =  '#/FontWriter/'+this.state.info_s.userid+'/'+this.state.info_s.id+'-'+this.state.info_s.fontset+'/'+fc;
            window.location.reload();
        }else{
            let c_f_index = 0;
            let n_f_index;
            for(let f in this.state.cur_fonts){
                if(this.state.cur_fonts[f].fontcode == this.state.info_s.fontcode){
                    c_f_index = parseInt(f);
                    break;
                };
            };

            n_f_index = c_f_index + 1 >= this.state.cur_fonts.length ? 0 : c_f_index + 1;
            window.location.href  =  '#/FontWriter/'+this.state.info_s.userid+'/'+this.state.info_s.id+'-'+this.state.info_s.fontset+'/'+this.state.cur_fonts[n_f_index].fontcode;
            window.location.reload();
        };
    };

    undo=()=>{
        this.mainCanvas.undo();
    };

    replay=()=>{
        this.mainCanvas.replay();
    };

    clear=()=>{
        this.mainCanvas.clear();
    };

    save=()=>{
        const lines = this.mainCanvas.save(100);

        if(this.state.settings_parameter_sub.gcodes.length >= 10){
            message.error('最多只能录入10个版本!');
            return;
        };

        if(typeof (lines) == 'string' && lines.length > 0){
            const pli = this.state.info_s;
            const data = {
                    target:'save',
                    token:window.localStorage.getItem('token'),
                    userID:pli.userid,
                    fontID:pli.id,
                    lines:lines,
                    fontset:pli.fontset,
                    font:pli.writting_font,
                    fontcode:pli.fontcode,
                };
            let S = this.state;
            Query('POST','/font',data,
                (response)=>{message.success(response.data.info,1.5);
                     if( typeof (this.state.cur_fonts) !== 'object' | (this.state.cur_fonts ? this.state.cur_fonts.length : '') === 0 ){
                        let fc;
                        let i_fc = parseInt(S.info_s.fontcode);

                        while (1) {
                            if (i_fc + 1 > S.info_s.fc_max) {
                                fc = this.state.info_s.fc_min;
                                break};

                            if(S.info_s.fonts.hasOwnProperty(i_fc + 1) ){
                                fc = i_fc + 1;
                                break}else{
                                i_fc ++;};
                            };
                        // const fc = parseInt(this.state.info_s.fontcode) === this.state.fn[this.state.info_s.fontset] ? 1 : parseInt(this.state.info_s.fontcode) + 1;
                        window.location.href  =  '#/FontWriter/'+this.state.info_s.userid+'/'+this.state.info_s.id+'-'+this.state.info_s.fontset+'/'+fc;
                        window.location.reload();
                     }else{

                        let c_f_index = 0;
                        let n_f_index;
                        for(let f in this.state.cur_fonts){
                            if(this.state.cur_fonts[f].fontcode == this.state.info_s.fontcode){
                                c_f_index = parseInt(f);
                                break;
                            };
                        };

                        n_f_index = c_f_index + 1 >= this.state.cur_fonts.length ? 0 : c_f_index + 1;
                        window.location.href  =  '#/FontWriter/'+this.state.info_s.userid+'/'+this.state.info_s.id+'-'+this.state.info_s.fontset+'/'+this.state.cur_fonts[n_f_index].fontcode;
                        window.location.reload();
                     };}
                     ,
                (response)=>message.error(response.data.info));
        }else {
            message.error('尚未进行书写！');
        };
    };

    moreOne=()=>{
        const lines = this.mainCanvas.save(100);
        const pli = this.state.info_s;

        if(this.state.settings_parameter_sub.gcodes.length >= 10){
            message.error('最多只能录入10个版本!');
            return;
        };


        if(typeof (lines) == 'string' && lines.length > 0) {

            const data = {
                    target: 'save',
                    token: window.localStorage.getItem('token'),
                    userID: pli.userid,
                    fontID: pli.id,
                    lines: lines,
                    fontset: pli.fontset,
                    font: pli.writting_font,
                    fontcode: pli.fontcode,
                };
            Query('POST','/font',data,
                (response)=>{message.success(response.data.info,1.5);window.location.reload();},
                (response)=>message.error(response.data.info));

        }else {
            message.error('尚未进行书写！');
        };
    };

    cancel=()=>{
        window.location.href =  '#/FontList/'+this.state.info_s.userid+'/'+this.state.info_s.id+'-'+this.state.info_s.fontset;
    };

    change=(id)=>{
        if(typeof(id)=='number'){
            this.setState({del_font:id,del_button_disable:false});
        }else {
            this.setState({del_font:-1,del_button_disable:true});
        };
    };

    bgChange=(n)=>{
        if(n != 5){
            const userid = this.state.info_s.userid;
            const font = this.state.info_s.writting_font;
            const k = this.state.bg[n];
            window.localStorage.setItem('bg_d',n);
            const s_c = '!#"$%&\'()*+,-./0123456789:;<=>?@ABCDEFGHIJKLMNOPQRSTUVWXYZ[\]_abcdefghijklmnopqrstuvwxyz{|}~'.indexOf(font) >= 0 ? 0.25*this.state.settings_parameter.dom_width + 'px' : '100%'
            this.mainCanvas.bgChange(n >= 0 ? 'url(/font?target=exmapleBg&userid='+userid+'&font='+ encodeURIComponent(font).replace(/\(/g,"%28").replace(/\)/g,"%29") +'&k='+k+'&bg_n='+n+')  no-repeat '+ s_c +' 100% ' : 'none');
        }else {
            document.getElementById('upload-input').click();
        };
    };

    showImg =(e)=> {
        e.persist();
        var t = this;
        var file = e.target.files[0];
        let imageDataReader = new FileReader();
        imageDataReader.readAsDataURL(file);

        imageDataReader.onload = function(){
            let imgValue=this.result;
            console.log(imgValue,typeof(imgValue))
            t.mainCanvas.bgChange('url('+ imgValue +') no-repeat 100% 100%');
        }

    };

    del_font=()=>{
        let pmp = this.props.match.params;

        const data = {
                target:'delFont',
                token:window.localStorage.getItem('token'),
                userID:pmp.userid,
                fontID:pmp.fontid,
                id:this.state.del_font,
            };
        Query('POST','/font',data,
            (response)=>{message.success(response.data.info);window.location.reload();},
            (response)=>message.error(response.data.info));
    };

    render() {
        if(!this.state.isloading) {
            return (
                <TweenOne style={{width:0,margin:'auto',overflow:'hidden'}} animation={{width:'100%',duration: 550}}>
                    <div id="FontWriter">
                        <Space direction="vertical">
                            <div style={{position:'relative'}}>
                                <h1>{this.state.info_s.writting_font}</h1>

                                <Input.Group compact style={{'position':'absolute','width':'auto','top':'0','right':'0'}}>
                                  <Select defaultValue="请选择" onChange={this.change}>
                                      {
                                          this.state.settings_parameter_sub.gcodes.map((item,index)=>{
                                              return <Option value={item.id}>{index+1}</Option>;
                                          })
                                      }
                                  </Select>
                                  <Button type='primary' disabled={this.state.del_button_disable} onClick={this.del_font}>删除字迹:</Button>
                                </Input.Group>
                            </div>

                            <div style={{overflow:'scroll hidden'}}>
                                <Canvas settings_parameter={{...this.state.settings_parameter_sub,'font_len':10}}
                                        onRef={(ref) => {this.subCanvas = ref;this.subCanvas.mode_set('sub');}}>
                                </Canvas>
                            </div>

                            <Canvas settings_parameter={{...this.state.settings_parameter}} disable-scroll="false"
                                    onRef={(ref) =>{ this.mainCanvas = ref;this.mainCanvas.mode_set('write');}}
                                    >
                            </Canvas>

                            <div className="buttons">
                                    {
                                        (() => {
                                            const p_a = {position:'absolute',top:'50%'};
                                            const pre_svg = [<svg style={{height:'50px',width:'50px'}}>
                                                                <circle cx="24" cy="24" r="24" style={{stroke:'#b9b9b9',fill:'rgba(244,244,244,0.8)'}}/>
                                                                <circle cx="24" cy="24" r="22" style={{stroke:'white',fill:'rgba(128,128,128,0.7)'}}/>
                                                                <line x1="21"  y1="13" x2="13"   y2="24" style={{'stroke':'#fefefe','stroke-width':'2px'}} />
                                                                <line x1="13"  y1="24" x2="21"   y2="35" style={{'stroke':'#fefefe','stroke-width':'2px'}} />
                                                                <line x1="32"  y1="13" x2="24"   y2="24" style={{'stroke':'#fefefe','stroke-width':'2px'}} />
                                                                <line x1="24"  y1="24" x2="32"   y2="35" style={{'stroke':'#fefefe','stroke-width':'2px'}} />
                                                            </svg>];
                                            const next_svg = [<svg style={{height:'50px',width:'50px'}}>
                                                                <circle cx="24" cy="24" r="24" style={{stroke:'#b9b9b9',fill:'rgba(244,244,244,0.8)'}}/>
                                                                <circle cx="24" cy="24" r="22" style={{stroke:'white',fill:'rgba(128,128,128,0.7)'}}/>
                                                                <line x1="15"  y1="13" x2="23"   y2="24" style={{'stroke':'#fefefe','stroke-width':'2px'}} />
                                                                <line x1="23"  y1="24" x2="15"   y2="35" style={{'stroke':'#fefefe','stroke-width':'2px'}} />
                                                                <line x1="26"  y1="13" x2="34"   y2="24" style={{'stroke':'#fefefe','stroke-width':'2px'}} />
                                                                <line x1="34"  y1="24" x2="26"   y2="35" style={{'stroke':'#fefefe','stroke-width':'2px'}} />
                                                            </svg>];
                                            let Node = [<Button onClick={this.undo}><RollbackOutlined />撤销</Button>,
                                                <Button onClick={this.replay}><YoutubeOutlined />回放</Button>,
                                                <Button onClick={this.clear}><HistoryOutlined />重置</Button>,
                                                <Button onClick={this.cancel}><CloseOutlined />取消</Button>,
                                                <Button className='azure' style={{width: 'calc(50% - 2px*2)',height:'40px',maxWidth:'none'}} onClick={this.moreOne}><EditOutlined /> {this.props.match.params.fontcode.indexOf('$') != '0' ? '再写一个' : '保存'}</Button>,];
                                            if (this.props.match.params.fontcode.indexOf('$') != '0') {
                                                Node.unshift(     <a href="javascript:void(0)" onClick={this.previous} style={{...p_a,width: '50px',left:'-25px',border:'none',background:'none'}}>{pre_svg}</a>);
                                                Node.splice(3, 0, <a href="javascript:void(0)" onClick={this.next} style={{...p_a,width: '50px',right:'-27px',border:'none',background:'none'}}>{next_svg}</a>,);
                                                Node.splice(-1, 0,<Button className='yellow' style={{width: 'calc(50% - 2px*2)',height:'40px',maxWidth:'none'}} onClick={this.save}><FormOutlined />保存</Button>,);
                                            };
                                            return Node;
                                        })()
                                    }
                            </div>

                            <Input.Group compact style={{'margin-bottom':'10px'}} >
                                  <Button type='primary' disabled={true} style={{'color':'#917f7f'}}>背景选择:</Button>
                                  <Select defaultValue={window.localStorage.getItem('bg_d')?parseInt(window.localStorage.getItem('bg_d')):0} onChange={this.bgChange} >
                                        <Option value={-1}>无背景</Option>;
                                        <Option value={0}>1号字体</Option>;
                                        <Option value={1}>2号字体</Option>;
                                        <Option value={2}>3号字体</Option>;
                                        <Option value={3}>4号字体</Option>;
                                        <Option value={4}>5号字体</Option>;
                                        <Option value={5}>自选背景</Option>
                                  </Select>
                                  <input id="upload-input" style={{'width':'0','height':'0','opacity':'0'}}  type="file" accept="image/jpg, image/png" onChange={e=>this.showImg(e)}/>
                            </Input.Group>
                        </Space>
                    </div>
                </TweenOne>
            );
        }else{
            return(
                <div> </div>
            )
        };

    };
}

export default loginStatusFilter(FontWriter);
