import React from 'react';
import '../css/basic.less';
import '../css/FD.less';
import { loginStatusFilter,Query } from './tools';
import {Card, Button, Space, Breadcrumb, message, Modal, Input} from 'antd';
import {Link} from "react-router-dom";
import {EditOutlined, EyeFilled, FireOutlined , EyeInvisibleFilled , ExclamationCircleOutlined , CheckOutlined} from "@ant-design/icons";
import {EditFilled,ToolFilled} from "@ant-design/icons";

const { confirm } = Modal;

class FontDetail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            info_s:{},
            is_loading:true,
            edit_switch:false,
            comment:'',
        };
    };

    componentWillMount(){

        if(!this.props.location.info){
            const pmp = this.props.match.params;
            const data = {
                    target:'queryFonts',
                    userID:pmp.userid,
                    fontID:pmp.fontid,
                    token:window.localStorage.getItem('token'),
                };
            Query("POST",'/font',data,
                (response)=>{window.location.href = '#/userFonts/'+window.localStorage.getItem('id');},
                (response)=>message.error(response.data.info,1.5));

        }else{
            this.setState({is_loading:false,info_s:this.props.location.info,comment:this.props.location.info.fontcomment});
        };
    }

    componentDidMount(){

    }

    edit_switch = ()=>{
        this.setState({edit_switch:true});
    };

    commentEdit = e =>{
        this.setState({comment:e.target.value});
    };

    editConfirm = ()=>{
        const pmp = this.props.match.params;
        const data = {
                target:'commentEidt',
                userID:pmp.userid,
                fontID:pmp.fontid,
                comment:this.state.comment,
                token:window.localStorage.getItem('token'),
            };
        Query("POST",'/font',data,
            (response)=>{const c = this.state.info_s.fontcomment;this.setState({edit_switch:false,c:this.state.comment})},
            (response)=>message.error(response.data.info,1.5));
    };

    fontUpgrade = (fontid,fontname,upset) => {
        const set_name = {0:'英文',1:'中文3702字',2:'中文7031字'};
        const data = {
                target:'upgrade',
                userID:window.localStorage.getItem('id'),
                token:window.localStorage.getItem('token'),
                fontID:fontid,
                upto:upset,
            };

        confirm({
                title: <span>是否要将字体“{fontname}”升级为<span className='c_blue'>“{set_name[upset]}”</span>？</span>,
                icon: <ExclamationCircleOutlined />,
                content: <span>确认升级后将<span className='c_red'>无法复原</span>！</span>,
                okText:'确认',
                cancelText:'取消',
                onOk() {
                    Query('POST','/font',data,
                         (response)=>{message.success(response.data.info);setTimeout(()=> window.location.reload(),1500);},
                         (response)=>message.error(response.data.info));
                },
                onCancel() {
                },
              });
    };

    fontOpen = (fontid,fontname) => {
        const data = {
                target:'open',
                userID:window.localStorage.getItem('id'),
                token:window.localStorage.getItem('token'),
                fontID:fontid,
            };

        confirm({
            title: <span>是否要将字体“ <span className='c_blue'>{fontname}</span>”公开？</span>,
                icon: <ExclamationCircleOutlined />,
                content: <span>确认后将<span className='c_red'>无法复原</span>！</span>,
                okText:'确认',
                cancelText:'取消',
                onOk() {
                    Query('POST','/font',data,
                         (response)=>{message.success(response.data.info);setTimeout(()=> window.location.reload(),1500);},
                         (response)=>message.error(response.data.info));
                },
                onCancel() {
                },
              });
    };

    fontDel = (fontid,fontname) => {
        const data = {
                target:'del',
                userID:window.localStorage.getItem('id'),
                token:window.localStorage.getItem('token'),
                fontID:fontid,
            };

        confirm({
            title: <span>是否要将字体“ <span className='c_blue'>{fontname}</span>”删除？</span>,
                icon: <ExclamationCircleOutlined />,
                content: <span>确认后将<span className='c_red'>无法复原</span>！</span>,
                okText:'确认',
                cancelText:'取消',
                onOk() {
                    Query('POST','/font',data,
                         (response)=>{message.success(response.data.info);setTimeout(()=> window.location.reload(),1500);},
                         (response)=>message.error(response.data.info));
                },
                onCancel() {
                },
              });
    };


    render() {

        return (
            <div id='FD'>
                {
                    !this.state.is_loading ?
                        <Space direction="vertical" style={{width: '100%',}}>
                    {
                        (()=>{
                            const pli = this.state.info_s;
                            const set_name = {0:'英文',1:'中文3702字',2:'中文7031字'};
                            const titleNode = () =>
                                <div>
                                    <Breadcrumb  separator="/">
                                        <Breadcrumb.Item><a href='/index'>首页</a></Breadcrumb.Item>
                                        <Breadcrumb.Item>{set_name[pli.fontset]}</Breadcrumb.Item>
                                    </Breadcrumb>
                                    <h1>{pli.fontname}</h1>
                                    <Breadcrumb  separator="·">
                                        <Breadcrumb.Item>{pli.username}</Breadcrumb.Item>
                                        <Breadcrumb.Item>{'创建于' + (pli.timeModify ? pli.timeModify : pli.timemodify)}</Breadcrumb.Item>
                                    </Breadcrumb>
                                </div>;

                            const S = {
                                'display': 'inline-block',
                                'height': '25px',
                                'font-size': '10px',
                                'padding': '0px 5px 0 5px',
                                'border-radius': '5px',
                                'line-height': '25px',
                            };

                            const FontUpGrade = this.state.info_s.fontset < 2 ? <Button style={S} className='blue c_white fr' onClick={()=>this.fontUpgrade(this.state.info_s.id,this.state.info_s.fontname,this.state.info_s.fontset+1)} >升级</Button> : '';
                            const beOpen = this.state.info_s.isopen !== 1 ? <Button style={S} className='green c_white fr' onClick={()=>this.fontOpen(this.state.info_s.id,this.state.info_s.fontname)}>公开</Button> : '';
                            const delButton = <Button style={S} className='red c_white fr' onClick={()=>this.fontDel(this.state.info_s.id,this.state.info_s.fontname)}>删除</Button>

                            const commentEdit_on = <p><Input style={{maxWidth:'200px'}} value={this.state.comment} onChange={this.commentEdit}/> <Button className='blue c_white' onClick={this.editConfirm}><CheckOutlined /></Button></p>;
                            const commentEdit_off= <p>{this.state.comment}{ this.state.info_s.userid == window.localStorage.getItem('id') ? <Button  onClick={()=>this.edit_switch()}><EditFilled /></Button> : '' }</p>;
                            return(
                                <div>
                                  <Card title={titleNode()}  className='card_container'>
                                    <p>说明:
                                        {this.state.edit_switch ? commentEdit_on : commentEdit_off }
                                    </p>
                                  </Card>

                                  <Card className='card_container'>
                                      { pli.userid == window.localStorage.getItem('id') ? <Button type="primary" style={{marginLeft:'20px'}}><Link to={{pathname:'/fontList/'+pli.userid+'/'+pli.id+'-'+pli.fontset,info:pli}}><EditFilled/>开始新书写</Link></Button> : ''}
                                  </Card>

                                  <Card className='card_container'>
                                      <p style={{display:'inline'}}>已完成{pli.now_num}字</p>

                                          { pli.userid == window.localStorage.getItem('id') ? FontUpGrade : ''}
                                          { pli.userid == window.localStorage.getItem('id') ? beOpen : ''}
                                          { pli.userid == window.localStorage.getItem('id') ? delButton : ''}

                                  </Card>

                                </div>);
                        })()
                    }
                        </Space>
                    : ''
                }
            </div>

        );

    };
}

export default loginStatusFilter(FontDetail);
