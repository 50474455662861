import React from 'react';
import {Form, Input, Button, Row, Col, Typography, message, Card, Space ,Breadcrumb } from 'antd';
import {Query} from './tools';
import '../css/basic.less'
import '../css/Form.less'
const { Title } = Typography;

class Fpwd extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            sub_dis:false,
            cd:5,

            mailConfirm:'邮箱确认',
        };

    };

    cdStart =()=>{
     this.setState({sub_dis:true,cd:5});

     this.timer = setInterval(()=>{
         if(this.state.cd >= 0) {
             this.setState({cd: this.state.cd - 1, sub_dis: true,mailConfirm:'(' + this.state.cd + ')'  });
         }else {
             clearInterval(this.timer)
             this.setState({cd:5,sub_dis:false,mailConfirm:'邮箱确认' });
         };
     },1000);

    };

    sendMailConfirm =()=>{
          const data = this.refs.myForm.getFieldValue();
          var reg = /^([a-zA-Z]|[0-9])(\w|\-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/;

          if(!data.mail && !reg.test(data.mail)){
              this.refs.myForm.validateFields(['mail']);
          }else {
              Query("POST",'/register',
                  {
                      target:'fpwd_confirmMail',
                      username:data.username,
                      mail:data.mail,
                  },
                (response)=>{message.success(response.data.info,1);this.cdStart();},
                (response)=>message.error(response.data.info,1.5));
          };

      };

    render() {

            const layout = {
              labelCol: { span: 8 },
              wrapperCol: { span: 10 },
            };
            const tailLayout = {
              wrapperCol: { offset: 8, span: 8 },
            };

            const onFinish = values => {
                const data = {
                        target:'Fpwd',
                        ...values
                    };
                Query("POST",'/register',data,
                    (response)=>{
                        message.success(response.data.info,1.5);
                        setTimeout(()=>{
                            window.localStorage.clear();
                            window.location.href = '/';
                        },1500);},
                    (response)=>message.error(response.data.info,1.5));

            };

            const onFinishFailed = errorInfo => {
            };

          return (
          <Form
              {...tailLayout}
              name="login"
              className="form"
              ref="myForm"
              initialValues={{ remember: true }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
            >
          <nav className='CommonForm'>

          <Row style={{'margin-bottom':'12px'}}>
              <Col span={12} offset={6}><Title level={3}>忘记密码</Title></Col>
          </Row>

          <Form.Item {...layout}
            // label="用户名"
            name="username"
            rules={[{ required: true, message: '请输入用户名！'},
                    {pattern:'^[\u002d\u002e\u0040\u005f\u4E00-\u9FA5A-Za-z0-9]+$',message: '不支持特殊字符'},
                    {min:1, message: '用户名不能小于1个字符！'},
                    {max:30, message: '用户名不能超过30个字符！'}]}
          >
            <Input  placeholder='请输入用户名'/>
          </Form.Item>

          <Form.Item {...layout}
            name="mail"
            rules={[ {
            validator: async (_, mail) => {
              const reg = /^([a-zA-Z]|[0-9])(\w|\-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/;
              if (!mail) {
                return Promise.reject(new Error('请输入邮箱！'));
              }else if(!reg.test(mail)){
                return Promise.reject(new Error('邮箱格式有误！'));
                }
            },
          }]}
          >
              <div>
                  <Input style={{width:'75%'}}  placeholder='注册用邮箱'/>
                  <a disabled={this.state.sub_dis} style={{display:'inline-block',width:'21%','margin-left':'4%'}} onClick={ () => this.sendMailConfirm() }>{this.state.mailConfirm}</a>
              </div>
          </Form.Item>

          <Form.Item {...layout}
            // label="新密码 "
            name="newPassword"
            rules={[{ required: true, message: '请输入新密码！' },
                    {pattern:'^[a-zA-Z0-9]*$',message: '只支持数字、英文'},
                    {min:6, message: '密码不能小于6个字符！'},
                    {max:16, message: '密码不能超过16个字符！'}]}
          >
            <Input.Password placeholder='请输入新密码'/>
          </Form.Item>

          <Form.Item {...layout}
            // label="确认新密码 "
            name="confirmNewPassword"
            rules={[{ required: true, message: '请再次输入新密码！' },
                    ({ getFieldValue }) => ({
                        validator(rule, value) {
                          if (!value || getFieldValue('newPassword') === value) {
                            return Promise.resolve();
                          }
                          return Promise.reject('两次密码输入不一致！');
                        },
                      }),
                    {pattern:'^[a-zA-Z0-9]*$',message: '只支持数字、英文'},
                    {min:6, message: '密码不能小于6个字符！'},
                    {max:16, message: '密码不能超过16个字符！'}]}
          >
            <Input.Password placeholder='确认密码'/>
          </Form.Item>

          <Form.Item {...layout}
            name="mail_p"
            rules={[{ required: true, message: '请输入邮箱验证码！' }]}
          >
            <Input  placeholder='邮箱验证码'/>
          </Form.Item>

          <Form.Item {...layout}>
              <div>
                  <nav style={{'text-align':'center'}}>
                    <Button type="primary" htmlType="submit">
                      提交
                    </Button>
                  </nav>
              </div>
          </Form.Item>
          </nav>
        </Form>
          );
    };
}

export default Fpwd;
