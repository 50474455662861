import React,{useEffect, useState, useRef } from 'react';
import { Layout } from 'antd';
import { HashRouter as Router, Route } from 'react-router-dom';

import '../css/basic.css';
import '../css/App.css';

import Nav from "./Nav";
import Middle from "./Middle";
import Bottom from "./Bottom";

const { Header, Footer, Content } = Layout;


function App() {
  useEffect(() => {


    }, []);

  return (
    <Layout>
        <Router>
          <Header id='top' >
              <Nav/>
          </Header>


          <Content id="middle">
              <Middle/>
          </Content>


          <Footer id="footer">
              <Bottom/>
          </Footer>
        </Router>
    </Layout>

  );
}

export default App;
