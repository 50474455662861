import React from 'react';
import {Space, Card, Breadcrumb, Pagination, message, Input, Select} from 'antd';
import CAROUSEL from './CAROUSEL';
import {Query,debounce} from './tools';
import Canvas from './Canvas';
import { Link } from 'react-router-dom';
import TweenOne from 'rc-tween-one';
import QueueAnim from 'rc-queue-anim';
import { FireOutlined ,EditOutlined,EyeFilled,StarFilled,StarOutlined,LoadingOutlined} from '@ant-design/icons';
import '../css/basic.less';
import '../css/FS.less';

const { Search } = Input;
const { Option } = Select;

class Fontshow extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

            resizeMark:false,

            fontlist:{
                content_arr:[],
            },

            fontsize:200,

            FavList:[],
            FavLoading:[],

            sortBase:'hotValue',

            title:'全部公开的字体',
            keywords:'',

            page_sum_fonts:0,
            page_max_fonts:10,

            isloading:true,
        };

        this.Fav = this.Fav.bind(this);
        this.pageChange = this.pageChange.bind(this);
    };

    componentWillMount = ()=>{
            const Fs = parseInt(((window.innerWidth <= 1000 ? window.innerWidth :1000)*0.95 - 5)/5);
            this.setState({ fontsize:Fs <= 200 ? Fs : 200 });

            window.onresize = ()=>{
                this.setState({resizeMark:true});
                setTimeout(()=>{this.setState({resizeMark:false});},500)
            };

    };

    componentDidMount = async ()=>{

        const data = {
                    target:'index',
                    id:window.localStorage.getItem('id'),
                    token:window.localStorage.getItem('token'),
                    page:1,
                    max:this.state.page_max_fonts,
                };
        await Query('POST','/font',data,
            (response)=>{
                var flca = this.state.fontlist;
                flca.content_arr = response.data.info.fonts;
                this.setState({ fontlist: flca , FavList: response.data.info.fav ,page_sum_fonts:response.data.info.sum_fonts ,isloading:false });
              });
        };

    componentWillUnmount(){

    };

    keyWordChange =(e)=>{
        this.setState({keywords:e.target.value});
    };

    keyWordsSearch=(e)=>{
        window.id_list = [];
        this.setState({isloading:true});

        const kw = this.state.keywords ? '有关“' + this.state.keywords + '”的字体' : '全部公开的字体';
        const data = {
            target:'index',
            id:window.localStorage.getItem('id'),
            token:window.localStorage.getItem('token'),
            sort:this.state.sortBase,
            keywords:this.state.keywords,
            page:1,
            max:this.state.page_max_fonts,
        };

        Query('POST','/font',data,(response)=>{
                    var flca = this.state.fontlist;
                    flca.content_arr = response.data.info.fonts;
                    this.setState({ fontlist: flca , FavList: response.data.info.fav ,page_sum_fonts:response.data.info.sum_fonts , title:kw ,isloading:false });
        });

    };

    sortChange=(sort) =>{
        this.setState({isloading:true,sortBase:sort});

        const data = {
            target:'index',
            id:window.localStorage.getItem('id'),
            token:window.localStorage.getItem('token'),
            sort:sort,
            keywords:this.state.keywords,
            page:1,
            max:this.state.page_max_fonts,
        };
        Query('POST','/font',data,(response)=>{
                    var flca = this.state.fontlist;
                    flca.content_arr = response.data.info.fonts;
                    this.setState({ fontlist: flca , FavList: response.data.info.fav ,page_sum_fonts:response.data.info.sum_fonts , isloading:false });
        });
    };

    Fav = async (id) => {
        const loadingList = this.state.FavLoading;
        const favList = this.state.FavList;
        loadingList.push(id);
        this.setState({FavLoading:loadingList});

        const data = {
                    target:'fav',
                    id:window.localStorage.getItem('id'),
                    token:window.localStorage.getItem('token'),
                    fontid:id,
                };
        await Query("POST",'/font',data,(response)=>{
                    //有就删除,没有就添加
                    [loadingList,favList].map(item => {
                        if(item.indexOf(id) >= 0){
                            item.splice(item.indexOf(id),1);
                        }else {
                            item.push(id);
                        };
                    });
                    this.setState({FavLoading:loadingList,FavList:favList});
              });

    };

    pageChange = (page,pagesize)=>{
        window.id_list = [];
        this.setState({isloading:true});

        const kw = this.state.keywords ? '有关“' + this.state.keywords + '”的字体' : '全部公开的字体';
        const data = {
            target:'index',
            id:window.localStorage.getItem('id'),
            token:window.localStorage.getItem('token'),
            page:page,
            max:pagesize,
            sort:this.state.sortBase,
            keywords:this.state.keywords,
        };

        Query("POST",'/font',data,(response)=>{
            this.setState({resizeMark:true})
            var flca = this.state.fontlist;
            flca.content_arr = response.data.info.fonts;
            this.setState({ fontlist: flca , FavList: response.data.info.fav ,page_sum_fonts:response.data.info.sum_fonts ,title:kw ,isloading:false });
            setTimeout(()=>{this.setState({resizeMark:false});},500)
            },
            (response)=>message.error(response.info));

    };

    render() {
        const banner_ele = [
                {n:0,bg:'1_bg.png',anList:[
                    {path:'1_0.png',ani:{delay:300} ,class:'M_W300 S_W150',css:{top:'-120px',left:'calc( 10% + 20px )'},css_m:{top:'-65px',left:'calc( 5% + 0px )'}},
                    {path:'1_1.png',ani:{delay:1300},class:'M_W200 S_W100',css:{top:'-20px',left:'calc( 10% + 80px )'},css_m:{top:'-30px',left:'calc( 5% + 35px )'}},
                    {path:'1_2.png',ani:{delay:2300},class:'M_W220 S_W110',css:{top:'-15px',left:'calc( 10% + 210px )'},css_m:{top:'-27px',left:'calc( 5% + 95px )'}},
                    {path:'1_3.png',ani:{delay:3000},class:'M_W500 S_W200',css:{top:'-20px',left:'calc( 50% + 20px )'},css_m:{top:'-20px',left:'calc( 50% - 20px )'}},
                    {path:'1_4.png',ani:{delay:3500},class:'M_W500 S_W150',css:{top:'20px',left:'calc( 50% + 20px )'},css_m:{top:'20px',left:'calc( 50% + 20px )'}},
                  ]},
                {n:1,bg:'2_bg.png',anList:[
                    {path:'2_0.png',ani:{delay:300} ,class:'M_W400 S_W200',css:{top:'-45px',left:'calc( 10% + 20px )'},css_m:{top:'-25px',left:'calc( 5% + 0px )'}},
                    {path:'2_1.png',ani:{delay:1300},class:'M_W400 S_W200',css:{top:'75px',left:'calc( 10% + 80px )'},css_m:{top:'25px',left:'calc( 5% + 20px )'}},
                    {path:'2_2.png',ani:{delay:2300},class:'M_W300 S_W200',css:{bottom:'-300px',left:'calc( 60% + 50px )'},css_m:{top:'-20px',left:'calc( 100% - 200px )'}},
                  ]},
                {n:2,bg:'3_bg.png',anList:[
                    {path:'3_0.gif',ani:{delay:300} ,class:'M_W300 S_W150',css:{top:'-130px',left:'calc( 10% + 20px )'},css_m:{top:'-55px',left:'calc( 5% + 0px )'}},
                    {path:'3_1.png',ani:{delay:800},class:'M_W300 S_W150', css:{top:'-120px',left:'calc( 10% + 28px )'},css_m:{top:'-50px',left:'calc( 5% + 5px )'}},
                    {path:'3_2.png',ani:{delay:1300},class:'M_W700 S_W300',css:{bottom:'-110px',left:'calc( 40% + 50px )'},css_m:{bottom:'-50px',right:'calc( 0% - 50px )'}},
                    {path:'3_3.png',ani:{delay:2000},class:'M_W700 S_W300',css:{bottom:'-180px',left:'calc( 40% + 100px )'},css_m:{bottom:'-80px',right:'calc( 0% - 60px )'}},
                    {path:'3_4.png',ani:{delay:2700},class:'M_W700 S_W300',css:{bottom:'-250px',left:'calc( 40% + 150px )'},css_m:{bottom:'-110px',right:'calc( 0% - 70px )'}},
                    {path:'3_5.png',ani:{delay:3400},class:'M_W700 S_W300',css:{bottom:'-320px',left:'calc( 40% + 200px )'},css_m:{bottom:'-140px',right:'calc( 0% - 80px )'}},
                    {path:'3_6.png',ani:{delay:4100},class:'M_W700 S_W300',css:{bottom:'-390px',left:'calc( 40% + 250px )'},css_m:{bottom:'-170px',right:'calc( 0% - 90px )'}},
                  ]},
            ];

        return (
            <div >
                    <Space direction="vertical" style={{width: '100%',}}>

                        <div className='ca_c' >
                            {
                                (()=>{
                                    if(this.state.resizeMark){
                                        return <CAROUSEL className='car' banner_ele={[]}  />
                                    }else {
                                        return <CAROUSEL className='car' banner_ele={banner_ele}  />
                                    };
                                })()
                            }

                        </div>

                        <div className='s_p'>

                            <TweenOne style={{width:0,margin:'auto'}} animation={{width:'100%',duration: 1050}}>
                               <div className='card_container s_p1'>
                               <span style={{'display':window.innerWidth < 450 ? 'none' : 'inline'}}>{this.state.title}</span>
                               {
                                   (()=>{
                                       // if(this.state.title != '全部公开的字体'){return <a style={{'margin-left':'20px','color':'blue'}} onClick={()=>{window.location.reload()}}>查看全部字体</a>}else { return <a ></a> };
                                   })()
                               }
                               <div className='search'>
                                   <span style={{'color':'#c8c8c8'}}>排序标准:</span>
                                   <Select defaultValue={'hotValue'} style={{marginLeft:'5px'}} onChange={this.sortChange}>
                                        <Option value={'hotValue'}>热度  </Option>;
                                        <Option value={'writeRate'}>书写率</Option>;
                                        <Option value={'repeatRate'}>复写率</Option>;
                                   </Select>
                               </div>
                               <Search placeholder="搜索字体名" onSearch={this.keyWordsSearch}  onChange={this.keyWordChange} value={this.state.keywords} style={{ width: window.innerWidth < 420 ? 70 : 200,position:'absolute',right:'10px',top:'10px'}}/>
                            </div>
                            </TweenOne>
                            <div className='s_p2'>
                            {
                                (() => {
                                    if(this.state.isloading){
                                        return <LoadingOutlined className='loadingIcon'  />
                                    }else {
                                        return this.state.fontlist.content_arr.map(item => {
                                        let I = item;
                                        I.fontid = item.id;

                                        const titleNode = () => <Link style={{marginLeft: '10px'}} to={{
                                            pathname: '/fontDetail/' + item.userid + '/' + item.id + '-' + item.fontset,
                                            info: I,
                                        }}><EditOutlined/>{item.fontname}</Link>;

                                        const ifFavNode = !window.localStorage.getItem('id') ? '' : (window.localStorage.getItem('id') == item.userid ? '' :
                                            <a href='/#' className={'fontmark ' + ( this.state.FavList.indexOf(item.id) >= 0 ? 'green' : 'yellow' ) }  onClick={() => this.Fav(item.id)}
                                               key={'_' + item.id}>
                                                {this.state.FavLoading.indexOf(item.id) >= 0 ? <LoadingOutlined /> : (this.state.FavList.indexOf(item.id) >= 0 ? [<StarFilled/>,'取消'] : [<StarOutlined/>,'收藏'])}
                                            </a>);

                                        const set_name = {0: '英文', 1: '中文3702字', 2: '中文7031字'};

                                        return (
                                            <QueueAnim  duration={1000} delay={100} interval={1000}
                                                       animConfig={[
                                                            { opacity: [1, 0], translateY: [0, 50] },
                                                            { opacity: [1, 0.4],}
                                                          ]} >
                                                <Card title={
                                                    [titleNode(),
                                                    <div><Breadcrumb separator="·" >
                                                        <Breadcrumb.Item>{set_name[item.fontset]}</Breadcrumb.Item>
                                                        {/*<Breadcrumb.Item>{item.isopen ? <EyeFilled/> : ''}</Breadcrumb.Item>*/}
                                                        <Breadcrumb.Item><p>{item.timeModify}</p></Breadcrumb.Item>
                                                    </Breadcrumb></div>]
                                                }

                                                      className='card_container' key={item.id}
                                                      extra={[
                                                          <div style={{'display':window.innerWidth < 420 ? 'flex' : 'inline','justify-content':'flex-end'}}>
                                                             {ifFavNode}
                                                              <div className="fontmark blue"
                                                                   key={'__' + item.id}>已书{parseInt(item.writeRate * 100) + '%'}</div>
                                                          </div>,
                                                          <div style={{'display':window.innerWidth < 420 ? 'flex' : 'inline','justify-content':'flex-end'}}>
                                                              <div className="fontmark azure"
                                                                    key={'___' + item.id}>复写{parseInt(item.repeatRate * 100) + '%'}</div>
                                                              <div className="fontmark red" key={'____' + item.id}>
                                                                    <FireOutlined/>{item.hot_value}</div>
                                                          </div>,]}
                                                >



                                                    <Canvas settings_parameter={{
                                                        'dom_id': 'canvas_' + item.id,
                                                        'dom_width': this.state.fontsize * 5,
                                                        'dom_height': this.state.fontsize,
                                                        'gcodes': item.gcodes,
                                                        offset_x: this.state.fontsize,
                                                        fontsize: this.state.fontsize,
                                                        resize: true,
                                                        cur_fonts: ' '
                                                    }}
                                                            style={{
                                                                width: '95%',
                                                                height: '200px',
                                                                margin: 'auto',
                                                                border: 'black 1px solid'
                                                            }}
                                                    onRef={(ref) => {
                                                        this.subCanvas = ref;
                                                        this.subCanvas.mode_set('d');
                                                    }}>
                                                    </Canvas>

                                                </Card>
                                            </QueueAnim>
                                        )
                                    });
                                    };
                                })()
                            }
                        </div>

                        </div>

                        <nav style={{'text-align':'center','margin-top':'20px','margin-bottom':'40px'}}>
                            <Pagination defaultCurrent={1} total={this.state.page_sum_fonts} defaultPageSize={this.state.page_max_fonts} onChange={this.pageChange} showSizeChanger={false}/>
                        </nav>
                    </Space>
            </div>
        );

    };
}

export default Fontshow;
