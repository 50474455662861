import React from 'react';
import { Form, Input, Button, Row, Col, Typography, Select ,message } from 'antd';
import { loginStatusCheck,Query} from './tools';
import '../css/basic.less'
import Qs from 'qs';
import axios from "axios";


const { Title } = Typography;
const { TextArea } = Input;
const { Option } = Select;

class NewFont extends React.Component {
    constructor(props) {
        super(props);
        this.t = {t:Date.now(),tid:''};
    }

    render() {

        const layout = {
          labelCol: { span: 8 },
          wrapperCol: { span: 8 },
        };
        const tailLayout = {
          wrapperCol: { offset: 8, span: 9 },
        };


        const onFinish = values => {
            const data = {
                    target:'newFont',
                    token:window.localStorage.getItem('token'),
                    userID:window.localStorage.getItem('id'),
                    ...values,
                };
            Query('POST','/font',data,
                (response)=>{message.success(response.data.info,1.5);
                setTimeout(()=>window.location.href='#/userFonts/'+window.localStorage.getItem('id'),1500);},
                (response)=>message.error(response.data.info));

        };

        const onFinishFailed = errorInfo => {
        };

        var that = this;

      return (

          <Form
              {...layout }
              name="login"
              className="form"
              initialValues={{ remember: true }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
            >

          <Row style={{'margin-bottom':'12px','text-align':'center'}}>
              <Col span={8} offset={8}><Title level={3}>创建字体</Title></Col>
          </Row>

          <Form.Item
            label="字体名:"
            labelAlign='right'
            name="fontname"
            rules={[{ required: true, message: '请输入字体名！' },
                ({ getFieldValue }) => ({
                async validator(rule, value){
                    const data = {
                        target:'fontNameUnique',
                        token:window.localStorage.getItem('token'),
                        userID:window.localStorage.getItem('id'),
                        name:value,
                    };
                    if(Date.now() - that.t.t > 2000 ){

                    }else {
                        clearTimeout(that.t.tid);
                    };
                    var a = await axios({
                                method: 'POST',
                                url: '/font',
                                data: Qs.stringify(data),
                            })
                    .then((response)=>{if(response.data.status != '300'){return Promise.reject(response.data.info)}});

                    that.t.tid = setTimeout(a,800)
                    that.t.t = Date.now();
                }
            })

            ]}
          >
            <Input  placeholder='请输入字体名'/>
          </Form.Item>

          <Form.Item
            label="字体集合:"
            labelAlign='right'
            name="fontset"
            rules={[{ required: true, message: '请选择字体集合！' }]}
          >
                <Select defaultValue="...请选择">
                  <Option value="alphabet">英文</Option>
                  <Option value="chinese3702">中文3702</Option>
                  <Option value="chinese7031">中文7031</Option>
                </Select>
          </Form.Item>

          <Form.Item
            label="是否共享:"
            labelAlign='right'
            name="isopen"
            rules={[{ required: true, message: '请选择是否共享！' }]}
          >
            <Select defaultValue="...请选择">
                <Option value={1}>共享</Option>
                <Option value={0}>不共享</Option>
            </Select>
          </Form.Item>

          <Form.Item
            label="说明:"
            name="fontcomment"
            rules={[]}
          >
            <TextArea rows={4} />
          </Form.Item>

          <Form.Item {...tailLayout}>
              <nav style={{'text-align':'center'}}>
                  <div>
                    <Button type="primary" htmlType="submit">
                      创建
                    </Button>
                  </div>
              </nav>
          </Form.Item>
        </Form>

      );
  }
}

export default loginStatusCheck(NewFont);
